import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=34a47d4e&scoped=true&"
import script from "./layout.vue?vue&type=script&lang=js&"
export * from "./layout.vue?vue&type=script&lang=js&"
import style0 from "./layout.vue?vue&type=style&index=0&id=34a47d4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a47d4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Topbar: require('/opt/build/repo/components/Topbar.vue').default,SideMenu: require('/opt/build/repo/components/SideMenu.vue').default,Footer: require('/opt/build/repo/components/Footer.vue').default,RightSidebar: require('/opt/build/repo/components/Right-sidebar.vue').default})
