import { environments } from "../config/env";

export default async ({ app }, inject) => {
  // console.log("start [configurator.js] " + new Date());
  let result = {};
  const env = environments[process.env.NUXT_ENV_ENVIRONMENT || "development"];
  const domain = process.env.NUXT_ENV_DOMAIN || window.location.hostname;
  const veruApiDomain = "https://config.vianova.ai";
  // const veruApiDomain = 'https://api.veru.co';
  // console.log("environemnt", {
  //   env: env,
  //   domain: domain,
  //   NUXT_ENV_VERU_APP_ID: process.env.NUXT_ENV_VERU_APP_ID,
  //   NUXT_ENV_ENVIRONMENT: process.env.NUXT_ENV_ENVIRONMENT,
  // });

  if (!process.env.NUXT_ENV_VERU_APP_ID) {
    result = await app.$axios.$get(
      `${veruApiDomain}/api/def4b2f2-3f8a-4c40-9cc4-d0d72af4ecb7/${env.flavor}/${domain}`
    );
  } else {
    result = await app.$axios.$get(
      `${veruApiDomain}/api/${process.env.NUXT_ENV_VERU_APP_ID}`
    );
  }

  app.$axios.defaults.baseURL = result.options.api_endpoint;

  if (process.env.NUXT_ENV_API_ENDPOINT) {
    app.$axios.defaults.baseURL = process.env.NUXT_ENV_API_ENDPOINT;
  }

  env.version = `${process.env.NUXT_ENV_PACKAGE_VERSION} - ${process.env.NUXT_ENV_GIT_HASH}`;

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let debug = params.debug;

  inject("organization", () => {
    return JSON.parse(localStorage.getItem("organization") || "{}");
  });

  inject("debug", debug);

  inject("tableSorting", () => {
    let key = "last_call_date";
    let organization = JSON.parse(localStorage.getItem("organization") || "{}");
    if (
      organization &&
      organization.settings &&
      organization.settings.patient_table_sorting
    ) {
      key = organization.settings.patient_table_sorting;
    } else if (
      result.options.tables &&
      result.options.tables.patient_table_sorting
    ) {
      key = result.options.tables.patient_table_sorting;
    }
    return {
      key,
    };
  });
  inject("checkDoNotDisturb", {
    enabled: result.options.slug !== "cannaheal",
  });

  inject("vianovaChat", {
    enabled: result.options.enabled_features.includes("chat"),
    video: result.options.chat ? result.options.chat.video : false,
    audio: result.options.chat ? result.options.chat.audio : false,
    expand: result.options.chat ? result.options.chat.expand : false,
    communication_redirect: result.options.chat
      ? result.options.chat.communication_redirect
      : false,
    direct_message_enabled: result.options.chat
      ? result.options.chat.direct_message_enabled
      : false,
    default_channel: result.options.chat
      ? result.options.chat.default_channel
      : "sms",
    overview_redirect: result.options.chat
      ? result.options.chat.overview_redirect
      : false,
    system_messages: result.options.chat
      ? result.options.chat.system_messages
      : true,
    hide_care_team: result.options.chat
      ? result.options.chat.hide_care_team
      : false,
    filters: result.options.chat ? result.options.chat.filters : {},
  });
  inject("welcome_sms_on_create_template", () => {
    let organization = JSON.parse(localStorage.getItem("organization") || "{}");
    let message = `Welcome to :app_name. You can get the app from the links below \nApple: :appstore_link \nGoogle: :play_store_link`;
    // "welcome_sms_on_create": false,
    if (
      organization &&
      organization.settings &&
      organization.settings.secure_sms &&
      organization.settings.secure_sms.welcome_sms_on_create_template
    ) {
      message = organization.settings.secure_sms.welcome_sms_on_create_template;
    }
    return message;
  });
  inject("welcome_sms_on_create", () => {
    let organization = JSON.parse(localStorage.getItem("organization") || "{}");
    let enabled = false;
    // "welcome_sms_on_create": false,
    if (
      organization &&
      organization.settings &&
      organization.settings.secure_sms &&
      organization.settings.secure_sms.welcome_sms_on_create
    ) {
      enabled = organization.settings.secure_sms.welcome_sms_on_create;
    }
    return enabled;
  });
  inject("isFeatureDisabled", (feature) => {
    let disabled_features = result.options.disabled_features || [];
    return disabled_features.includes(feature);
  });
  inject("secure_sms", () => {
    let organization = JSON.parse(localStorage.getItem("organization") || "{}");
    let enabled = false;
    if (
      organization &&
      organization.settings &&
      organization.settings.secure_sms &&
      organization.settings.secure_sms.enabled
    ) {
      enabled = organization.settings.secure_sms.enabled;
    }
    return enabled;
  });
  inject("ccmAssessments", {
    enabled: result.options.enabled_features.includes("ccm_assessments"),
    copy_to_encounter_note: result.options.ccm_assessments
      ? result.options.ccm_assessments.copy_to_encounter_note
      : false,
  });

  inject("connect", env);
  inject("vianovaConfig", result);
  inject("veruFlag", (feature) => {
    // if (["notes_v2"].includes(feature)) {
    //   return true;
    // }

    return result.options.enabled_features.includes(feature);
  });
  inject("roleName", (role) => {
    if (result.slug === "dental-ai") {
      if (role === "Physician") {
        return app.i18n.t("Dentist");
      }
      if (role === "Nurse") {
        return app.i18n.t("Dental Assistant");
      }
    }
    return app.i18n.t(role);
  });

  // console.log("completed [configurator.js]" + new Date());
};
