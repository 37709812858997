import https from "https";

const qs = require("querystring");

export default ({ app }, inject) => {
  const agent = new https.Agent({
    rejectUnauthorized: false,
  });
  const http = {
    async getUsers(
      organizationId,
      perPage,
      currentPage,
      filters,
      parent_id,
      disease_id,
      sort,
      search
    ) {
      let base_params = {
        sort: sort,
        per_page: perPage,
        page: currentPage,
      };
      let response = await app.$axios.$get(`${organizationId}/patients`, {
        params: {
          ...filters,
          ...base_params,
          parent_id,
          disease_id,
          search,
        },
      });
      return response;
    },
    async getUserTransactions(organizationId, perPage, currentPage, parent_id) {
      let base_params = {
        per_page: perPage,
        page: currentPage,
      };
      let response = await app.$axios.$get(
        `${organizationId}/patients/transactions`,
        {
          params: {
            ...base_params,
            parent_id,
          },
        }
      );
      return response;
    },
    async getUserDetails(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}`
      );
      return response;
    },
    async getOrganizations() {
      let response = await app.$axios.$get("organizations");
      return response;
    },
    async getOnboardingProviders(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/providers`);
      return response;
    },
    async getCoordinators(organizationId, role) {
      return await app.$axios.$get(`${organizationId}/users`, {
        params: {
          role,
        },
      });
    },
    async getAppInformation(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/apps`);
    },
    async getLocations(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/locations`);
    },
    async getMemberships(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/memberships`);
    },
    //Payments
    async getPaymentIntent(organizationId) {
      return await app.$axios.$get(`${organizationId}/payment/intent`);
    },
    async getPaymentMethods(organizationId) {
      return await app.$axios.$get(`${organizationId}/payment/list`);
    },
    async addPaymentMethod(organizationId, payload) {
      return await app.$axios.$post(`${organizationId}/payment/add`, payload);
    },
    async addDefaultPaymentMethod(organizationId, payload) {
      return await app.$axios.$post(
        `${organizationId}/payment/default`,
        payload
      );
    },
    async deletePaymentMethod(organizationId, payment) {
      return await app.$axios.$delete(`${organizationId}/payment/${payment}`);
    },
    async createCharge(organizationId, payload) {
      return await app.$axios.$post(
        `${organizationId}/payment/charge`,
        payload
      );
    },
    //Chat Patients
    async getSearchedChatPatients(
      organizationId,
      currentPage,
      perPage,
      search,
      inbox,
      new_message
    ) {
      let request = search
        ? `${organizationId}/patients?search=${search}&page=${currentPage}&per_page=${perPage}`
        : `${organizationId}/patients?page=${currentPage}&per_page=${perPage}`;
      request = inbox ? `${request}&inbox=true` : request;
      request = new_message ? `${request}&new_message=true` : request;
      return await app.$axios.$get(request);
    },
    async getChatPatients(organizationId, currentPage, perPage) {
      return await app.$axios.$get(
        `${organizationId}/chat/inbox?page=${currentPage}&per_page=${perPage}`
      );
    },
    async getPatientConversation(organizationId, chatId, currentPage, perPage) {
      return await app.$axios.$get(
        `${organizationId}/chat/${chatId}?page=${currentPage}&per_page=${perPage}`
      );
    },
    async patientNewConversation(organizationId, patientId) {
      return await app.$axios.$post(
        `${organizationId}/chat/start/${patientId}`
      );
    },
    //Lab Vitals
    async getLabVitalsTypes(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/lab-vitals/types`
      );
      return response;
    },
    async getLabVitals(organizationId, patientId, baseFilters) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/lab-vitals`,
        {
          params: baseFilters,
        }
      );
      return response;
    },
    async addLabVitals(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/lab-vitals`,
        payload
      );
      return response;
    },
    async editLabVital(organizationId, patientId, vitalId, payload) {
      let response = await app.$axios.$patch(
        `${organizationId}/patients/${patientId}/lab-vitals/${vitalId}`,
        payload
      );
      return response;
    },
    async deleteLabVital(organizationId, patientId, vitalId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/lab-vitals/${vitalId}`
      );
      return response;
    },
    //Care Plan
    async getActivity(organizationId, patientId, vital, start_date, end_date) {
      let response =
        (await start_date) && end_date
          ? app.$axios.$get(
              `${organizationId}/patients/${patientId}/activities/${vital}/responses?start_date=${start_date}&end_date=${end_date}`
            )
          : start_date && !end_date
          ? app.$axios.$get(
              `${organizationId}/patients/${patientId}/activities/${vital}/responses?start_date=${start_date}`
            )
          : app.$axios.$get(
              `${organizationId}/patients/${patientId}/activities/${vital}/responses`
            );
      return response;
    },
    async saveActivity(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/activities/responses`,
        payload
      );
      return response;
    },
    async fetchNotes(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/care_notes`
      );
      return response;
    },
    async fetchAssessmentHistory(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/assessments`
      );
      return response;
    },
    async createNote(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/care_notes`,
        payload
      );
      return response;
    },
    async deleteNote(organizationId, patientId, careNoteId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/care_notes/${careNoteId}`
      );
      return response;
    },
    async updateNote(organizationId, patientId, careNoteId, payload) {
      let response = await app.$axios.$put(
        `${organizationId}/patients/${patientId}/care_notes/${careNoteId}`,
        payload
      );
      return response;
    },
    async fetchPharmacyList(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/pharmacies`);
      return response;
    },
    async deletePharmacy(organizationId, patientId, pharmacyId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/pharmacies/${pharmacyId}`
      );
      return response;
    },
    async fetchAlerts(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/alerts`
      );
      return response;
    },
    async fetchOrgAlerts(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/alerts`);
      return response;
    },
    async fetchAppointments(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/appointments`
      );
      return response;
    },
    async addAppointment(organizationId, patientId, payload) {
      let response = await app.$axios.post(
        `${organizationId}/patients/${patientId}/appointments`,
        payload
      );
      return response;
    },
    async updateAppointment(organizationId, patientId, activityId, payload) {
      let response = await app.$axios.$patch(
        `${organizationId}/patients/${patientId}/appointments/${activityId}`,
        payload
      );
      return response;
    },
    async deleteAppointment(organizationId, patientId, activityId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/activities/${activityId}`
      );
      return response;
    },
    async fetchOrgAppointments(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/appointments`);
      return response;
    },
    async addPatient(payload) {
      let response = await app.$axios.$post(`/patients`, payload);
      return response;
    },
    async getSpecialists(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/specialists`);
      return response;
    },
    async fetchMedications(organizationId, patientId, startDate, endDate) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/medications?start_date=${startDate}&end_date=${endDate}`
      );
      return response;
    },
    async fetchMedicationDetails(
      organizationId,
      patientId,
      medicationId,
      start_date,
      end_date
    ) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/medications/${medicationId}?start_date=${start_date}&end_date=${end_date}`
      );
      return response;
    },
    async addMedication(organizationId, patientId, medicationId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/medications/${medicationId}/log`,
        payload
      );
      return response;
    },
    async deleteMedication(organizationId, patientId, medicationId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/medications/${medicationId}`
      );
      return response;
    },
    async deletePharmacy(organizationId, patientId, pharmacyId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/medications/${pharmacyId}`
      );
      return response;
    },
    async updatePatientMedication(
      organizationId,
      patientId,
      medicationId,
      payload
    ) {
      let response = await app.$axios.$patch(
        `${organizationId}/patients/${patientId}/medications/${medicationId}`,
        payload
      );
      return response;
    },
    async addPatientMedication(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/medications`,
        payload
      );
      return response;
    },
    // async fetchConversations(organizationId, patientId) {
    //   let response = await app.$axios.$get(`${organizationId}/patients/${patientId}/conversations`)
    //   return response
    // },
    // async fetchVitals(organizationId, patientId) {
    //   let response = await app.$axios.$get(`${organizationId}/patients/${patientId}/vitals`)
    //   return response
    // },
    // async fetchEducation(organizationId, patientId) {
    //   let response = await app.$axios.$get(`${organizationId}/patients/${patientId}/education`)
    //   return response
    // },
    // async fetchReminders(organizationId, patientId) {
    //   let response = await app.$axios.$get(`${organizationId}/patients/${patientId}/reminders`)
    //   return response
    // },
    // async fetchTasks(organizationId, patientId) {
    // let response = await app.$axios.$get(`${organizationId}/patients/${patientId}/activities`)
    // return response
    // },
    async createBookActivity(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/create-books`,
        payload
      );
      return response;
    },
    async fetchBooks(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/books`
      );
      return response;
    },
    async getPatientAlerts(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/alerts`
      );
      return response;
    },
    async getPatientAlertRules(organizationId, patientId, currentPage) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/alerts/rules?page=${currentPage}`
      );
      return response;
    },
    async createPatientAlertRules(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/alerts/rules`,
        payload
      );
      return response;
    },
    async updatePatientAlertRules(
      organizationId,
      patientId,
      alertRuleId,
      payload
    ) {
      let response = await app.$axios.$patch(
        `${organizationId}/patients/${patientId}/alerts/rules/${alertRuleId}`,
        payload
      );
      return response;
    },
    async deletePatientAlertRules(organizationId, patientId, alertRuleId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/alerts/rules/${alertRuleId}`
      );
      return response;
    },
    async getRCBData(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/activities/rcb_data`
      );
      return response;
    },
    async getActivityResponses(
      organizationId,
      patientId,
      activity_type,
      start_date,
      end_date
    ) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/activities/${activity_type}/responses?start_date=${start_date}&end_date=${end_date}`
      );
      return response;
    },
    async fetchActivity(
      organizationId,
      patientId,
      type,
      start_date,
      end_date,
      responses = null
    ) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/${type}?start_date=${start_date}&end_date=${end_date}&responses=${responses}`
      );
      return response;
    },
    async fetchActivityTypes(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/activity_types`);
      return response;
    },
    async addActivityForPatient(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/activities`,
        payload
      );
      return response;
    },
    async updateActivityForPatient(
      organizationId,
      patientId,
      payload,
      activityId
    ) {
      let response = await app.$axios.$patch(
        `${organizationId}/patients/${patientId}/activities/${activityId}`,
        payload
      );
      return response;
    },
    async updateOrderForActivity(organizationId, patientId, payload) {
      let response = await app.$axios.$put(
        `${organizationId}/patients/${patientId}/activities/order`,
        payload
      );
      return response;
    },
    async deleteActivity(organizationId, patientId, activityId) {
      let response = await app.$axios.$delete(
        `${organizationId}/patients/${patientId}/activities/${activityId}`
      );
      return response;
    },
    async getUserTableColumns(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/table_columns`);
      return response;
    },
    async getPatientTableColumns(organizationId) {
      let response = await app.$axios.$get(
        `${organizationId}/patient_table_columns`
      );
      return response;
    },
    async getAllColumnFields(organizationId) {
      let response = await app.$axios.$get(
        `${organizationId}/table_columns/fields`
      );
      return response;
    },
    async createOrUpdateColumnFields(organizationId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/table_columns`,
        payload
      );
      return response;
    },
    async startTracker(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/trackers`,
        payload
      );
      return response;
    },
    async stopTracker(organizationId, patientId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/patients/${patientId}/trackers/stop`,
        payload
      );
      return response;
    },
    async getActiveTracker(organizationId, patientId, type) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/trackers/active?type=${type}`
      );
      return response;
    },
    async getCcmRpm(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/trackers`
      );
      return response;
    },
    async getDeviceTypes(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/devices/types`
      );
      return response;
    },
    async getDevices(organizationId, patientId, currentPage) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/devices`,
        {
          params: {
            page: currentPage,
          },
        }
      );
      return response;
    },
    async getCompletedAcitivities(organizationId, patientId, start_date) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/activities/completed?start_date=${start_date}`
      );
      return response;
    },
    async getInompletedAcitivities(organizationId, patientId, start_date) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/activities/incomplete?start_date=${start_date}`
      );
      return response;
    },
    async getAssignedAcitivities(organizationId, patientId) {
      let response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/activities/assigned`
      );
      return response;
    },
    async getQuery(organizationId, query) {
      let response = await app.$axios.$get(`${organizationId}/query/${query}`);
      return response;
    },
    async getBoards(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/boards`);
      return response;
    },
    async createBoard(organizationId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/boards`,
        payload
      );
      return response;
    },
    async updateBoard(organizationId, boardId, payload) {
      let response = await app.$axios.$patch(
        `${organizationId}/boards/${boardId}`,
        payload
      );
      return response;
    },
    async deleteBoard(organizationId, boardId) {
      let response = await app.$axios.$delete(
        `${organizationId}/boards/${boardId}`
      );
      return response;
    },
    async getBoardsTiles(organizationId, boardId) {
      let response = await app.$axios.$get(
        `${organizationId}/boards/${boardId}/tiles`
      );
      return response;
    },
    async createBoardTile(organizationId, boardId, payload) {
      let response = await app.$axios.$post(
        `${organizationId}/boards/${boardId}/tiles`,
        payload
      );
      return response;
    },
    async updateBoardTile(organizationId, boardId, tileId, payload) {
      let response = await app.$axios.$patch(
        `${organizationId}/boards/${boardId}/tiles/${tileId}`,
        payload
      );
      return response;
    },
    async deleteBoardTiles(organizationId, boardId, tileId) {
      let response = await app.$axios.$delete(
        `${organizationId}/boards/${boardId}/tiles/${tileId}`
      );
      return response;
    },

    //Admin side
    //Roles
    async getRoles(organizationId) {
      let response = await app.$axios.$get(`admin/${organizationId}/roles`);
      return response;
    },
    //Organizations
    async getAdminOrganizations(perPage, currentPage) {
      const response = await app.$axios.$get(`admin/organizations`, {
        params: {
          per_page: perPage,
          page: currentPage,
        },
      });
      return response;
    },
    async getAdminOrganizationsList(perPage, currentPage) {
      const response = await app.$axios.$get(`admin/organizations-list`, {
        params: {
          per_page: perPage,
          page: currentPage,
        },
      });
      return response;
    },
    async getOrganizationDetails(organizationId) {
      let response = await app.$axios.$get(
        `admin/organizations/${organizationId}`
      );
      return response;
    },
    async createOrganization(payload) {
      let response = await app.$axios.$post(`admin/organizations`, payload);
      return response;
    },
    async updateOrganization(organizationId, payload) {
      let response = await app.$axios.$patch(
        `admin/organizations/${organizationId}`,
        payload
      );
      return response;
    },
    async deleteOrganization(organizationId) {
      let response = await app.$axios.$delete(
        `admin/organizations/${organizationId}`
      );
      return response;
    },
    async getOrganizationSettings(organizationId) {
      let response = await app.$axios.$get(`admin/${organizationId}/settings`);
      return response;
    },
    async updateOrganizationSettings(organizationId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/settings`,
        payload
      );
      return response;
    },

    //Notifications
    async getNotifications(organizationId, perPage, currentPage, search = '') {
      let base_params = {
        per_page: perPage,
        page: currentPage,
        search: search,
      }
      return await app.$axios.$get(`admin/${organizationId}/notifications`, {params: base_params})
    },
    async sendNotification(organizationId, payload) {
      return await app.$axios.$post(`admin/${organizationId}/send-notification`, payload)
    },

    //Users
    async getOrganizationUsers(organizationId, role, search, currentPage) {
      let response = await app.$axios.$get(`admin/${organizationId}/users`, {
        params: {
          role: role,
          page: currentPage,
          search: search,
        },
      });
      return response;
    },
    async getUser(organizationId, userId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/users/${userId}`
      );
      return response;
    },
    async getUserApplicableOrganizations(organizationId, userId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/users/${userId}/applicable-organizations`
      );
      return response;
    },
    async createUser(organizationId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/users`,
        payload
      );
      return response;
    },
    async updateUser(organizationId, userId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/users/${userId}`,
        payload
      );
      return response;
    },
    async deleteUser(organizationId, userId) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/users/${userId}`
      );
      return response;
    },
    //Specialists
    async getOnboardingSpecialities(organizationId) {
      let response = await app.$axios.$get(`${organizationId}/specialities`);

      return response;
    },

    async getSpecialities(organizationId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/specialities`
      );
      return response;
    },
    async getAdminSpecialists(organizationId, perPage, currentPage) {
      let base_params = {
        per_page: perPage,
        page: currentPage,
      };
      let response = await app.$axios.$get(
        `admin/${organizationId}/specialists`,
        {
          params: base_params,
        }
      );
      return response;
    },
    async getSpecialist(organizationId, specialistId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/specialists/${specialistId}`
      );
      return response;
    },
    async createSpecialist(organizationId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/specialists`,
        payload
      );
      return response;
    },
    async updateSpecialist(organizationId, specialistId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/specialists/${specialistId}`,
        payload
      );
      return response;
    },
    async deleteSpecialist(organizationId, specialistId) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/specialists/${specialistId}`
      );
      return response;
    },
    //Providers
    async getProviders(organizationId) {
      let response = await app.$axios.$get(`admin/${organizationId}/providers`);
      return response;
    },
    async getProvider(organizationId, providerId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/providers/${providerId}`
      );
      return response;
    },
    async createProvider(organizationId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/providers`,
        payload
      );
      return response;
    },
    async updateProvider(organizationId, providerId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/providers/${providerId}`,
        payload
      );
      return response;
    },
    async deleteProvider(organizationId, providerId) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/providers/${providerId}`
      );
      return response;
    },
    //Measuremenets
    async getAdminActivityTypes(organizationId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/activity-types`
      );
      return response;
    },
    async getAdminMeasurementsDurations(organizationId, perPage, currentPage) {
      let base_params = {
        per_page: perPage,
        page: currentPage,
      };
      let response = await app.$axios.$get(
        `admin/${organizationId}/measurement-durations`,
        {
          params: base_params,
        }
      );
      return response;
    },
    async getAdminMeasurementDuration(organizationId, measurementDurationId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/measurement-durations/${measurementDurationId}`
      );
      return response;
    },
    async createAdminMeasurementDuration(organizationId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/measurement-durations`,
        payload
      );
      return response;
    },
    async updateAdminMeasurementDuration(
      organizationId,
      measurementDurationId,
      payload
    ) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/measurement-durations/${measurementDurationId}`,
        payload
      );
      return response;
    },
    async deleteAdminMeasurementDuration(
      organizationId,
      measurementDurationId
    ) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/measurement-durations/${measurementDurationId}`
      );
      return response;
    },
    //Diseases
    async getDiseases(organizationId, base_params) {
      let response = await app.$axios.$get(`admin/${organizationId}/diseases`, {
        params: base_params,
      });
      return response;
    },
    async getDisease(organizationId, diseaseId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/diseases/${diseaseId}`
      );
      return response;
    },
    async createDisease(organizationId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/diseases`,
        payload
      );
      return response;
    },
    async updateDisease(organizationId, diseaseId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/diseases/${diseaseId}`,
        payload
      );
      return response;
    },
    async deleteDisease(organizationId, diseaseId) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/diseases/${diseaseId}`
      );
      return response;
    },

    // Disease Rules
    async getDiseaseRule(organizationId, diseaseId, baseParams) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/diseases/${diseaseId}/disease-rules`,
        {
          params: baseParams,
        }
      );
      return response;
    },
    async createDiseaseRule(organizationId, diseaseId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/diseases/${diseaseId}/disease-rules`,
        payload
      );
      return response;
    },
    async updateDiseaseRule(organizationId, diseaseId, planId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/diseases/${diseaseId}/disease-rules/${planId}`,
        payload
      );
      return response;
    },
    async deleteDiseaseRule(organizationId, diseaseId, planId) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/diseases/${diseaseId}/disease-rules/${planId}`
      );
      return response;
    },

    // End of Disease Rules

    //Diseases Plans
    async getDiseasePlan(organizationId, diseaseId, baseParams) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/diseases/${diseaseId}/plan`,
        {
          params: baseParams,
        }
      );
      return response;
    },
    async createDiseasePlan(organizationId, diseaseId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/diseases/${diseaseId}/plan`,
        payload
      );
      return response;
    },
    async updateDiseasePlan(organizationId, diseaseId, planId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/diseases/${diseaseId}/plan/${planId}`,
        payload
      );
      return response;
    },
    async deleteDiseasePlan(organizationId, diseaseId, planId) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/diseases/${diseaseId}/plan/${planId}`
      );
      return response;
    },
    //Onboard Medications
    async getAdminMedications(organizationId, perPage, currentPage) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/medications`,
        {
          params: {
            per_page: perPage,
            page: currentPage,
          },
        }
      );
      return response;
    },
    async getAdminMedication(organizationId, medicationId) {
      let response = await app.$axios.$get(
        `admin/${organizationId}/medications/${medicationId}`
      );
      return response;
    },
    async createAdminMedication(organizationId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/medications`,
        payload
      );
      return response;
    },
    async updateAdminMedication(organizationId, medicationId, payload) {
      let response = await app.$axios.$patch(
        `admin/${organizationId}/medications/${medicationId}`,
        payload
      );
      return response;
    },
    async deleteAdminMedication(organizationId, medicationId) {
      let response = await app.$axios.$delete(
        `admin/${organizationId}/medications/${medicationId}`
      );
      return response;
    },

    // Library
    async createBook(organizationId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/book`,
        payload
      );
      return response;
    },
    async updateBook(organizationId, bookId, payload) {
      let response = await app.$axios.$post(
        `admin/${organizationId}/book/${bookId}`,
        payload
      );
      return response;
    },
    async getBooks(organizationId) {
      let response = await app.$axios.$get(`admin/${organizationId}/book`);
      return response;
    },

    // Library
    async fetchTopics(organizationId, params) {
      return await app.$axios.$get(`admin/${organizationId}/topics`, {
        params,
      });
    },

    async createTopic(organization_id, topicData) {
      return await app.$axios.$post(
        `admin/${organization_id}/topics`,
        topicData
      );
    },
    async updateTopic(organization_id, topicId, topicData) {
      return await app.$axios.$put(
        `admin/${organization_id}/topics/${topicId}`,
        topicData
      );
    },

    async deleteTopic(organizationId, topicId) {
      return await app.$axios.$delete(
        `admin/${organizationId}/topics/${topicId}`
      );
    },

    async orderTopics(organizationId, topics, start_order) {
      return await app.$axios.$post(`admin/${organizationId}/topics/order`, {
        topics,
        start_order,
      });
    },

    // Article
    async fetchArticles(organization_id, params) {
      return await app.$axios.$get(`admin/${organization_id}/articles`, {
        params,
      });
    },
    async createArticle(organization_id, articleData) {
      return await app.$axios.$post(
        `admin/${organization_id}/articles`,
        articleData
      );
    },
    async updateArticle(organization_id, articleId, articleData) {
      return await app.$axios.$put(
        `admin/${organization_id}/articles/${articleId}`,
        articleData
      );
    },
    async deleteArticle(organizationId, articleId) {
      return await app.$axios.$delete(
        `admin/${organizationId}/articles/${articleId}`
      );
    },

    async orderArticles(organizationId, topic_id, articles, start_order) {
      await app.$axios.$post(
        `admin/${organizationId}/topics/${topic_id}/articles/order`,
        {
          articles,
          start_order,
        }
      );
    },

    // Achievements
    async getAchievements(organizationId, params) {
      const response = await app.$axios.$get(`${organizationId}/achievements`, {
        params,
      });
      return response;
    },

    // Badges
    async getBadges(organizationId, patientId) {
      const response = await app.$axios.$get(
        `${organizationId}/patients/${patientId}/badges`
      );
      return response;
    },

    // Rewards
    async fetchRewards(organizationId, params) {
      return await app.$axios.$get(`${organizationId}/rewards`, { params });
    },
    async fetchPatientRewards(organizationId, patientId, params) {
      return await app.$axios.$get(
        `${organizationId}/patients/${patientId}/rewards/all`,
        { params }
      );
    },
    async fetchClaimedRewards(organizationId, patientId, params) {
      return await app.$axios.$get(
        `${organizationId}/patients/${patientId}/rewards/claimed`,
        { params }
      );
    },
    async createReward(organizationId, rewardData) {
      return await app.$axios.$post(`${organizationId}/rewards`, rewardData);
    },
    async updateReward(organizationId, rewardId, rewardData) {
      return await app.$axios.$put(
        `${organizationId}/rewards/${rewardId}`,
        rewardData
      );
    },
    async deleteReward(organizationId, rewardId) {
      return await app.$axios.$delete(`${organizationId}/rewards/${rewardId}`);
    },
    async claimReward(organizationId, patientId, rewardId) {
      return await app.$axios.$post(
        `${organizationId}/patients/${patientId}/rewards/${rewardId}/claim`
      );
    },

    // Documents
    async fetchDocuments(organization_id, params) {
      return await app.$axios.$get(`${organization_id}/documents`, {
        params,
      });
    },
    async createDocument(organization_id, documentData) {
      return await app.$axios.$post(
        `${organization_id}/documents`,
        documentData
      );
    },
    async createFromSharedDocuments(organization_id, documentData) {
      return await app.$axios.$post(
        `${organization_id}/documents/from-shared`,
        documentData
      );
    },
    async updateDocument(organization_id, documentId, documentData) {
      return await app.$axios.$put(
        `${organization_id}/documents/${documentId}`,
        documentData
      );
    },
    async deleteDocument(organizationId, documentId) {
      return await app.$axios.$delete(
        `${organizationId}/documents/${documentId}`
      );
    },

    // Shared Documents
    async fetchSharedDocuments(organization_id, params) {
      return await app.$axios.$get(
        `admin/${organization_id}/shared-documents`,
        {
          params,
        }
      );
    },

    async createSharedDocument(organization_id, sharedDocumentData) {
      return await app.$axios.$post(
        `admin/${organization_id}/shared-documents`,
        sharedDocumentData
      );
    },

    async updateSharedDocument(
      organization_id,
      sharedDocumentId,
      sharedDocumentData
    ) {
      return await app.$axios.$put(
        `admin/${organization_id}/shared-documents/${sharedDocumentId}`,
        sharedDocumentData
      );
    },

    async deleteSharedDocument(organization_id, sharedDocumentId) {
      return await app.$axios.$delete(
        `admin/${organization_id}/shared-documents/${sharedDocumentId}`
      );
    },

    // Dental Bookings
    async fetchBookings(organizationId, params) {
      return await app.$axios.$get(`${organizationId}/dental/bookings`, {
        params,
      });
    },

    async createBooking(organizationId, bookingData) {
      return await app.$axios.$post(
        `${organizationId}/dental/bookings`,
        bookingData
      );
    },

    async updateBooking(organizationId, bookingId, bookingData) {
      return await app.$axios.$put(
        `${organizationId}/dental/bookings/${bookingId}`,
        bookingData
      );
    },

    async updateBookingStatus(organizationId, bookingId, payload) {
      return await app.$axios.$patch(
        `${organizationId}/dental/bookings/${bookingId}/status`,
        payload
      );
    },

    async fetchProviderHours(organizationId, params) {
      return await app.$axios.$get(`${organizationId}/dental/provider-hours`, {
        params,
      });
    },

    async fetchServices(organizationId) {
      return await app.$axios.$get(`${organizationId}/dental/services`);
    },
  };

  inject("backend", http);
};
