//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from "vuex";
import Pusher from "pusher-js";
import ImagePreviewModal from "@/components/chat/ImagePreviewModal.vue";
import MessageDeleteConfirmation from "@/components/chat/MessageDeleteConfirmation.vue";

export default {
  data() {
    return {
      pusher: null,
      inboxIsOpen: false,
      previewVisible: false,
      previewUrl: "",
      organization_id: "",
      conversation_id: "",
      message_id: "",
    };
  },
  components: {
    ImagePreviewModal,
    MessageDeleteConfirmation,
  },
  computed: {
    ...mapState("chat", {
      conversations: (state) => state.conversations,
    }),
    openConversations() {
      return this.conversations.filter(
        (conversation) => !conversation.isMinimized
      );
    },
    minimizedItems() {
      return this.conversations.filter(
        (conversation) => conversation.isMinimized
      );
    },
    chatWindowStyle() {
      let right = window.innerWidth <= 600 ? 0 : 185;
      return (index, conversation) => {
        return {
          right: `${(this.inboxIsOpen ? 425 : right) + index * 405}px`,
        };
      };
    },
  },
  methods: {
    ...mapActions("chat", ["toggleMinimizeConversation"]),
    getChatWindowRef(chatId) {
      return "chatWindow-" + chatId;
    },
    minimizeChats() {},
    toggleMinimizeChatWindow(payload) {
      this.toggleMinimizeConversation({
        chatId: payload.chatId,
        isMinimized: true,
      });
      console.log(`Minimizing chat ${payload.chatId}`);
    },
    closeChatWindow(chatId) {
      this.$store.dispatch("chat/closeChat", { chatId });
    },
    handleInboxToggle(isOpen) {
      this.inboxIsOpen = isOpen;
      this.$store.commit("chat/setInboxOpen", isOpen);
    },
    handleChatSelected(chat) {
      let state = this.$store.state.chat.minimizedChatInputStates[chat.id];
      if (state) {
        this.$nextTick(() => {
          let refName = this.getChatWindowRef(chat.id);
          if (
            this.$refs[refName] &&
            this.$refs[refName].length &&
            typeof this.$refs[refName][0].restoreState === "function"
          ) {
            this.$refs[refName][0].restoreState(state);
          }
        });
      }
    },
    openImagePreview(url) {
      this.previewUrl = url;
      this.$refs.imagePreviewModal.show();
    },
    openDeleteModal(conversation_id, organization_id, message_id) {
      this.conversation_id = conversation_id;
      this.organization_id = organization_id;
      this.message_id = message_id;
      this.$refs.deleteMessageModal.show();
    },
    async deleteMessage(deleteForEveryone = false) {
      const action = deleteForEveryone
        ? "chat/deleteForAllMessage"
        : "chat/deleteMessage";
      let payload = {
        organization_id: this.organization_id,
        conversation_id: this.conversation_id,
        message_id: this.message_id,
      };

      await this.$store.dispatch(action, payload);
      this.$store.dispatch("chat/getInbox", {
        organizationId: localStorage.getItem("organizationId"),
        currentPage: 1,
      });
      this.$refs.deleteMessageModal.hide();
    },
    handleMessageSent({ chatId, message }) {},
    // async subscribe() {
    //   this.pusher = new Pusher(this.$connect.pusherAppKey, { cluster: "mt1" });
    //   const channel = this.pusher.subscribe(`user_${this.$auth.user.id}`);
    //   channel.bind("new-message-event", (event) => {
    //     if (event.message.sender_id == this.$auth.user.id) return;
    //     this.$store.dispatch("chat/receivedNewMessageNotification", event);
    //   });
    // },
  },
  mounted() {
    // this.subscribe();
  },
};
