//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";

export default {
  props: {
    conversations: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      showMoreOptions: [],
    };
  },
  computed: {
    ...mapGetters("chat", {
      initialsForConversation: "initialsForConversation",
      nameForConversation: "nameForConversation",
      getUnreadCountForConversation: "getUnreadCountForConversation",
    }),
  },
  methods: {
    minimizeAllChats() {
      this.$store.commit("chat/setMinimizeAll");
    },
    closeAll() {
      this.$store.commit("chat/setCloseAll");
    },
    showMore(conversation_id, visible) {
      if (!conversation_id) {
        this.showMoreOptions = [];
        return;
      }
      this.$set(this.showMoreOptions, conversation_id, visible);
    },
    selectChat(chat) {
      console.log(chat);
      this.$store.dispatch("chat/selectChat", { chatId: chat.id });
      this.$emit("chatSelected", chat);
    },
  },
};
