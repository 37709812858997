import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/achievements.js'), 'achievements.js')
  resolveStoreModules(require('../store/articles.js'), 'articles.js')
  resolveStoreModules(require('../store/assessments.js'), 'assessments.js')
  resolveStoreModules(require('../store/badges.js'), 'badges.js')
  resolveStoreModules(require('../store/bookings.js'), 'bookings.js')
  resolveStoreModules(require('../store/chat.js'), 'chat.js')
  resolveStoreModules(require('../store/dashboard.js'), 'dashboard.js')
  resolveStoreModules(require('../store/documents.js'), 'documents.js')
  resolveStoreModules(require('../store/filters.js'), 'filters.js')
  resolveStoreModules(require('../store/general_chat.js'), 'general_chat.js')
  resolveStoreModules(require('../store/groups.js'), 'groups.js')
  resolveStoreModules(require('../store/helpers.js'), 'helpers.js')
  resolveStoreModules(require('../store/magic.js'), 'magic.js')
  resolveStoreModules(require('../store/oasis_assessment.js'), 'oasis_assessment.js')
  resolveStoreModules(require('../store/oasis_patient_filters.js'), 'oasis_patient_filters.js')
  resolveStoreModules(require('../store/organization_appointments.js'), 'organization_appointments.js')
  resolveStoreModules(require('../store/patient-details.js'), 'patient-details.js')
  resolveStoreModules(require('../store/patient.js'), 'patient.js')
  resolveStoreModules(require('../store/rcbResponses.js'), 'rcbResponses.js')
  resolveStoreModules(require('../store/rewards.js'), 'rewards.js')
  resolveStoreModules(require('../store/shared-documents.js'), 'shared-documents.js')
  resolveStoreModules(require('../store/store.js'), 'store.js')
  resolveStoreModules(require('../store/topics.js'), 'topics.js')
  resolveStoreModules(require('../store/trackers.js'), 'trackers.js')
  resolveStoreModules(require('../store/tracks.js'), 'tracks.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/modules/index.js'), 'modules/index.js')
  resolveStoreModules(require('../store/modules/admin.js'), 'modules/admin.js')
  resolveStoreModules(require('../store/modules/advanced-filters.js'), 'modules/advanced-filters.js')
  resolveStoreModules(require('../store/modules/alerts.js'), 'modules/alerts.js')
  resolveStoreModules(require('../store/modules/am_patient_filters.js'), 'modules/am_patient_filters.js')
  resolveStoreModules(require('../store/modules/amdiabetes-search.js'), 'modules/amdiabetes-search.js')
  resolveStoreModules(require('../store/modules/appointment-history.js'), 'modules/appointment-history.js')
  resolveStoreModules(require('../store/modules/appointments.js'), 'modules/appointments.js')
  resolveStoreModules(require('../store/modules/bookAppointmentSteps.js'), 'modules/bookAppointmentSteps.js')
  resolveStoreModules(require('../store/modules/cannaPatientFilters.js'), 'modules/cannaPatientFilters.js')
  resolveStoreModules(require('../store/modules/care_plan_activities.js'), 'modules/care_plan_activities.js')
  resolveStoreModules(require('../store/modules/care-plan.js'), 'modules/care-plan.js')
  resolveStoreModules(require('../store/modules/cgm_sync.js'), 'modules/cgm_sync.js')
  resolveStoreModules(require('../store/modules/chats.js'), 'modules/chats.js')
  resolveStoreModules(require('../store/modules/communication.js'), 'modules/communication.js')
  resolveStoreModules(require('../store/modules/config.js'), 'modules/config.js')
  resolveStoreModules(require('../store/modules/conversation.js'), 'modules/conversation.js')
  resolveStoreModules(require('../store/modules/dashboard.js'), 'modules/dashboard.js')
  resolveStoreModules(require('../store/modules/groups.js'), 'modules/groups.js')
  resolveStoreModules(require('../store/modules/layout.js'), 'modules/layout.js')
  resolveStoreModules(require('../store/modules/medications.js'), 'modules/medications.js')
  resolveStoreModules(require('../store/modules/memberships.js'), 'modules/memberships.js')
  resolveStoreModules(require('../store/modules/merge_patient.js'), 'modules/merge_patient.js')
  resolveStoreModules(require('../store/modules/notes.js'), 'modules/notes.js')
  resolveStoreModules(require('../store/modules/notification.js'), 'modules/notification.js')
  resolveStoreModules(require('../store/modules/notifications.js'), 'modules/notifications.js')
  resolveStoreModules(require('../store/modules/onboarding_amdiabetes.js'), 'modules/onboarding_amdiabetes.js')
  resolveStoreModules(require('../store/modules/onboarding.js'), 'modules/onboarding.js')
  resolveStoreModules(require('../store/modules/organizations.js'), 'modules/organizations.js')
  resolveStoreModules(require('../store/modules/patient_list_headers.js'), 'modules/patient_list_headers.js')
  resolveStoreModules(require('../store/modules/patient-notes.js'), 'modules/patient-notes.js')
  resolveStoreModules(require('../store/modules/payments.js'), 'modules/payments.js')
  resolveStoreModules(require('../store/modules/providers_coordinators.js'), 'modules/providers_coordinators.js')
  resolveStoreModules(require('../store/modules/rpm-reports.js'), 'modules/rpm-reports.js')
  resolveStoreModules(require('../store/modules/search.js'), 'modules/search.js')
  resolveStoreModules(require('../store/modules/two_factor_authentication.js'), 'modules/two_factor_authentication.js')
  resolveStoreModules(require('../store/modules/users.js'), 'modules/users.js')
  resolveStoreModules(require('../store/modules/vitals.js'), 'modules/vitals.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
