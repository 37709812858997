export const state = () => ({
  data: [],
  data_pagination: {
    current_page: 0,
    item_per_page: 0,
    last_page: 0,
    total: 0,
  },
  search: null
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_PAGINATION(state, data) {
    state.data_pagination = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  }
};

export const actions = {
  async getNotifications({ commit }, { organizationId, perPage, currentPage, search }) {
    let result = await this.$backend.getNotifications(
      organizationId,
      perPage,
      currentPage,
      search
    );
    commit('SET_DATA', result.data);
    commit('SET_PAGINATION', result.pagination);
    return result;
  },
  async sendNotification({ commit }, { organizationId, payload }) {
    return await this.$backend.sendNotification(organizationId, payload);
  },
  setSearchInput({ commit }, search) {
    commit('SET_SEARCH', search);
  }
};

export const getters = {
  getSearch: (state) => () => {
    return state.search;
  }
};
