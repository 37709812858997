import axios from "axios";

export const state = () => ({
  rtm: {
    categories: [0, 0, 0],
    cpt_codes: {
      enrolled: {
        percentage: null,
        count: null,
      },
      rtm_20_minutes_count: {
        percentage: null,
        count: null,
      },
      rtm_40_minutes_count: {
        percentage: null,
        count: null,
      },
      rtm_16_days_count: {
        percentage: null,
        count: null,
      },
    },
  },
  ccm: {
    cpt_codes: {
      enrolled: {
        percentage: null,
        count: null,
      },
      ccm_20_minutes_count: {
        percentage: null,
        count: null,
      },
      ccm_40_minutes_count: {
        percentage: null,
        count: null,
      },
    },
  },
  rpm: {
    cgm: [0, 0, 0],
    activities: {
      blood_pressure: {
        high: {
          percentage: null,
          count: null,
        },
        in_range: {
          percentage: null,
          count: null,
        },
        low: {
          percentage: null,
          count: null,
        },
      },
      glucose: {
        high: {
          percentage: null,
          count: null,
        },
        in_range: {
          percentage: null,
          count: null,
        },
        low: {
          percentage: null,
          count: null,
        },
      },
      heart_rate: {
        high: {
          percentage: null,
          count: null,
        },
        in_range: {
          percentage: null,
          count: null,
        },
        low: {
          percentage: null,
          count: null,
        },
      },
    },
    alerts: {},
    cpt_codes: {
      enrolled: {
        percentage: null,
        count: null,
      },
      rpm_16_days_count: {
        percentage: null,
        count: null,
      },
      rpm_20_minutes_count: {
        percentage: null,
        count: null,
      },
      rpm_40_minutes_count: {
        percentage: null,
        count: null,
      },
    },
  },
});

export const mutations = {
  setRTMCategorize(state, data) {
    state.rtm.categories = [
      data["data"].active_count,
      data["data"].inactive_count,
      data["data"].dormant_count,
    ];
  },
  setRTMData(state, data) {
    state.rtm.cpt_codes.enrolled.percentage =
      data.percentage_change.rtm_enrolled;
    state.rtm.cpt_codes.enrolled.count = data.selected_month.rtm_enrolled;

    state.rtm.cpt_codes.rtm_20_minutes_count.percentage =
      data.percentage_change.rtm_20_minutes_count;
    state.rtm.cpt_codes.rtm_20_minutes_count.count =
      data.selected_month.rtm_20_minutes_count;

    state.rtm.cpt_codes.rtm_40_minutes_count.percentage =
      data.percentage_change.rtm_40_minutes_count;
    state.rtm.cpt_codes.rtm_40_minutes_count.count =
      data.selected_month.rtm_40_minutes_count;

    state.rtm.cpt_codes.rtm_16_days_count.percentage =
      data.percentage_change.rtm_16_days_count;
    state.rtm.cpt_codes.rtm_16_days_count.count =
      data.selected_month.rtm_16_days_count;
  },
  setCGMData(state, data) {
    state.rpm.cgm = [
      data["data"]["active_count"],
      data["data"]["inactive_count"],
      data["data"]["dormant_count"],
    ];
  },

  setCCMData(state, data) {
    state.ccm.cpt_codes.enrolled.percentage =
      data.percentage_change.ccm_enrolled;
    state.ccm.cpt_codes.enrolled.count = data.selected_month.ccm_enrolled;
    state.ccm.cpt_codes.ccm_20_minutes_count.percentage =
      data.percentage_change["ccm_20_minutes_count"];
    state.ccm.cpt_codes.ccm_20_minutes_count.count =
      data.selected_month["ccm_20_minutes_count"];
    state.ccm.cpt_codes.ccm_40_minutes_count.percentage =
      data.percentage_change["ccm_40_minutes_count"];
  },
  setActivitiesData(state, data) {
    state.rpm.activities = data;
  },
  setAlertData(state, data) {
    state.rpm.alerts = data;
  },
  setRPMData(state, data) {
    state.rpm.cpt_codes.enrolled.percentage =
      data.percentage_change.rpm_enrolled;
    state.rpm.cpt_codes.enrolled.count = data.selected_month.rpm_enrolled;
    state.rpm.cpt_codes.rpm_16_days_count.percentage =
      data.percentage_change["rpm_16_days_count"];
    state.rpm.cpt_codes.rpm_16_days_count.count =
      data.selected_month["rpm_16_days_count"];
    state.rpm.cpt_codes["rpm_20_minutes_count"].percentage =
      data.percentage_change["rpm_20_minutes_count"];
    state.rpm.cpt_codes["rpm_20_minutes_count"].count =
      data.selected_month["rpm_20_minutes_count"];
    state.rpm.cpt_codes["rpm_40_minutes_count"].percentage =
      data.percentage_change["rpm_40_minutes_count"];
    state.rpm.cpt_codes["rpm_40_minutes_count"].count =
      data.selected_month["rpm_40_minutes_count"];
  },
};

export const actions = {
  async getRTMCategorize(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/rtm/patients/categorize`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setRTMCategorize", result.data);
  },
  async getRtmData({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/rtm/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setRTMData", result.data);
  },
  async getActivities(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.$get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/activity-responses/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setActivitiesData", result.data);
  },
  async getRPMData({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/rpm/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setRPMData", result.data);
  },
  async getAlertData(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/alerts/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setAlertData", result.data);
  },
  async getCGM({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/cgm/patients/categorize`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setCGMData", result.data);
  },
  async getCCM({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/ccm/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setCCMData", result.data);
  },
};

export const getters = {
  getRpmData: (state) => (key) => {
    return state.rpm.cpt_codes;
  },
  getActivitiesData: (state) => () => {
    return state.rpm.activities;
  },
  getAlertsData: (state) => () => {
    return state.rpm.alerts;
  },
  getCGMData: (state) => () => {
    return state.rpm.cgm;
  },
  getCCMData: (state) => () => {
    return state.ccm.cpt_codes;
  },
  getRTMData: (state) => () => {
    return state.rtm.cpt_codes;
  },
  getRTMCategorize: (state) => () => {
    return state.rtm.categories;
  },
};
