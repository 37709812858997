import { render, staticRenderFns } from "./ChatManager.vue?vue&type=template&id=1b6b78ee&"
import script from "./ChatManager.vue?vue&type=script&lang=js&"
export * from "./ChatManager.vue?vue&type=script&lang=js&"
import style0 from "./ChatManager.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatWindow: require('/opt/build/repo/components/chat/ChatWindow.vue').default,MinimizedItemsWindow: require('/opt/build/repo/components/chat/MinimizedItemsWindow.vue').default,InboxWindow: require('/opt/build/repo/components/chat/InboxWindow.vue').default,ImagePreviewModal: require('/opt/build/repo/components/chat/ImagePreviewModal.vue').default,MessageDeleteConfirmation: require('/opt/build/repo/components/chat/MessageDeleteConfirmation.vue').default})
