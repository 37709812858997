//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Pusher from "pusher-js";
import AudioRecorder from "~/utils/audioRecorder";

export default {
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedFile: null,
      audioFile: null,
      isRecording: false,
      sendingError: null,
      sendingMessage: false,
      pusher: null,
      channel: null,
      sendingFile: false,
      loading: false,
      organization_id: null,
      newMessage: "",
      audioPlaying: false,
      isModalOpen: false,
      selectedMessageId: null,
      uploadProgress: 0,
      sendingAudio: false,
      uploadingSelectedFile: false,
      uploadingAudioFile: false,
      uploadedFileData: null,
      uploadedAudioData: null,
      previewAudio: false,
      audioElement: null,
      savingAudio: false,
      recordingDuration: 0,
      recordingInterval: null,
      isPlaying: false,
      audioProgress: 0,
    };
  },
  computed: {
    ...mapGetters("chat", {
      nameForConversation: "nameForConversation",
      firstParticipant: "firstParticipant",
      conversationMessages: "conversationMessages",
      messageTimestamp: "messageTimestamp",
      messageBody: "messageBody",
      resourceUrl: "resourceUrl",
      messageSize: "messageSize",
    }),
    messages() {
      const messages = this.conversationMessages(this.conversation.id);
      if (!this.$vianovaChat.system_messages) {
        return messages.filter((message) => message.type !== "system");
      }

      this.scrollToBottom();
      return messages;
    },
    formattedDuration() {
      const minutes = Math.floor(this.recordingDuration / 60);
      const seconds = this.recordingDuration % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    audioProgressStyle() {
      // Always return the gradient, even when progress is 0
      return {
        background: `linear-gradient(to right, #6569CF ${this.audioProgress}%, #979bff ${this.audioProgress}%)`,
      };
    },
  },
  async mounted() {
    console.log(this.conversation.id, "OPENED");
    this.loading = true;
    this.organization_id = localStorage.getItem("organizationId");
    await this.$store.dispatch("chat/fetchMessages", {
      organization_id: this.organization_id,
      conversation_id: this.conversation.id,
      deleted: true,
    });
    this.loading = false;

    this.subscribe();

    // Wait for Vue to update the DOM after loading is complete
    await this.$nextTick();
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  },
  watch: {
    sendingFile(newVal) {
      if (newVal) {
        this.scrollToUploadIndicator();
      }
    },
    sendingAudio(newVal) {
      if (newVal) {
        this.scrollToUploadIndicator();
      }
    },
    messages: {
      handler() {
        this.$nextTick(() => {
          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
        });
      },
      deep: true,
    },
  },
  methods: {
    async downloadAudio(message) {
      const audioUrl = `${this.$connect.AWS_BUCKET_NAME}/${
        JSON.parse(message.body).url
      }`;
      try {
        const response = await fetch(audioUrl);
        const blob = await response.blob();

        // Create a blob URL
        const blobUrl = window.URL.createObjectURL(blob);

        // Create and trigger download
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download =
          JSON.parse(message.body).url.split("/").pop() || "audio.mp3";
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.log(error);
        this.showError(`Download failed: ${error}`);
      }
    },
    async subscribe() {
      if (!this.pusher) {
        this.pusher = new Pusher(this.$connect.pusherAppKey, {
          cluster: "mt1",
          logToConsole: true,
        });
      }

      if (this.channel) {
        this.channel.unbind("message-deleted-event", this.newDeletedMessage);
        this.channel.unsubscribe();
      }

      this.channel = this.pusher.subscribe(
        `conversation_${this.conversation.id}`
      );

      this.channel.bind("message-deleted-event", this.newDeletedMessage);
      this.$store.dispatch("chat/getUnreadMessageCount");
    },
    newDeletedMessage(event) {
      console.log(event);
      if (event.message.deleted_for) {
        this.$store.commit("chat/setMessageDeleted", {
          conversation_id: this.conversation.id,
          message_id: event.message.id,
          deletedAt: new Date(),
        });
      }
    },
    goToPatientCommunications(chat) {
      if (!this.$vianovaChat.communication_redirect) return;
      let patientId = chat.conversation.participants.find((participant) =>
        participant.messageable_type.includes("Patient")
      ).messageable_id;
      let from = chat.conversation_id;
      this.toggleMinimize();
      this.$router.push({
        name:
          this.$vianovaConfig.options.communication_path ??
          "users-id-communications",
        params: { id: patientId },
        query: { from },
      });
    },
    scrollToUploadIndicator() {
      this.$nextTick(() => {
        if (this.$refs.chatBody) {
          this.$refs.chatBody.scrollTop = this.$refs.chatBody.scrollHeight;
        }
      });
    },
    goToPatientOverview(chat) {
      if (!this.$vianovaChat.overview_redirect) return;
      let patientId = chat.conversation.participants.find((participant) =>
        participant.messageable_type.includes("Patient")
      ).messageable_id;
      this.toggleMinimize();
      this.$router.push({
        name:
          this.$vianovaConfig.options.overview_path ??
          "users-id-overview-index-vitals",
        params: { id: patientId },
        query: { t: Date.now() },
      });
    },
    formatTimestamp(timestamp) {
      return moment(timestamp).format("LT");
    },
    formatDate(timestamp) {
      return moment(timestamp).format("MM/DD/YYYY");
    },
    isFirstMessageOfDay(message, index) {
      if (!this.messages.length) return false;
      if (index === 0) return true;
      const currentDate = moment(message.created_at).startOf("day");
      const previousMessageDate = moment(
        this.messages[index - 1].created_at
      ).startOf("day");
      return !currentDate.isSame(previousMessageDate);
    },
    getMessagePositionClass(message, index) {
      // Ensure `message` is valid
      if (!message || !message.sender || !message.created_at) {
        return "invalid-message";
      }

      const previousMessage = this.messages[index - 1] || null;
      const nextMessage = this.messages[index + 1] || null;

      const currentDate = moment(message.created_at).startOf("day");
      const previousDate = previousMessage?.created_at
        ? moment(previousMessage.created_at).startOf("day")
        : null;
      const nextDate = nextMessage?.created_at
        ? moment(nextMessage.created_at).startOf("day")
        : null;
      if (message.type === "system") return "single-bubble";
      const isFirstInGroup =
        !previousMessage ||
        previousMessage.sender?.id !== message.sender.id ||
        previousMessage.type === "system" ||
        !currentDate.isSame(previousDate);

      const isLastInGroup =
        !nextMessage ||
        nextMessage.sender?.id !== message.sender.id ||
        nextMessage.type === "system" ||
        !currentDate.isSame(nextDate);

      if (message.type === "system" || (isFirstInGroup && isLastInGroup)) {
        return "single-bubble";
      } else if (isFirstInGroup) {
        return message?.sender?.id === this.$auth.user.id
          ? "first-sender-bubble"
          : "first-receiver-bubble";
      } else if (isLastInGroup) {
        return message?.sender?.id === this.$auth.user.id
          ? "last-sender-bubble"
          : "last-receiver-bubble";
      } else {
        return message?.sender?.id === this.$auth.user.id
          ? "middle-sender-bubble"
          : "middle-receiver-bubble";
      }
    },
    systemTypeMessage(message) {
      if (JSON.parse(message.body).action_type === "left") {
        return "left_chat";
      }
      if (JSON.parse(message.body).action_type === "joined") {
        return "joined_message";
      }
      return "";
    },
    isFirstMessageInGroup(message, index) {
      // Ensure `message` is valid
      if (!message || !message.sender || !message.created_at) {
        return false; // Treat invalid messages as not being the first in a group
      }

      if (index === 0 && message.type !== "system") return true;

      const previousMessage = this.messages[index - 1] || null;

      // If the current or previous message is of type "system", it's the start of a new group
      if (message.type === "system") {
        return false;
      }

      if (previousMessage.type === "system") return true;

      const currentDate = moment(message.created_at).startOf("day");
      const previousDate = previousMessage?.created_at
        ? moment(previousMessage.created_at).startOf("day")
        : null;

      // Check if sender has changed or if the date is different
      return (
        !previousMessage ||
        previousMessage.sender?.id !== message.sender.id ||
        !currentDate.isSame(previousDate)
      );
    },
    messageContent(message) {
      return this.messageBody(message, this);
    },
    getRouteLink() {
      if (this.$route.name.includes("civiclee")) {
        return {
          name: "civiclee-users-id-overview",
          params: { id: this.firstParticipant(this.conversation).id },
        };
      } else if (this.$vianovaConfig.slug === "dental-ai") {
        return {
          name: "users-id-dental-overview",
          params: { id: this.firstParticipant(this.conversation).id },
        };
      } else {
        return {
          name: "users-id-overview-index-vitals",
          params: { id: this.firstParticipant(this.conversation).id },
        };
      }
    },
    openModal(message) {
      this.$emit(
        "open-delete-modal",
        this.conversation.id,
        this.organization_id,
        message.id
      );
    },
    formatSender(sender) {
      if (sender && sender.deleted_at) {
        const getInitials = (name) =>
          name
            .split(" ")
            .map((n) => n[0].toUpperCase())
            .join("");
        return `${getInitials(sender.display_name)} (deleted user)`;
      } else {
        return sender ? sender.display_name : "";
      }
    },
    handleModalClose() {},
    determineOrientation(event, message) {
      const media = event.target;
      let isLandscape = null;
      media.classList.remove("full-width", "small-width");
      if (media.naturalWidth) {
        isLandscape = media.naturalWidth > media.naturalHeight;
      } else {
        isLandscape = media.videoWidth > media.videoHeight;
      }
      if (isLandscape) {
        media.classList.add("full-width");
      } else {
        media.classList.add("small-width");
      }
    },
    openPreview(message) {
      if (message.type === "image") {
        this.$emit("open-image-preview", this.resourceUrl(message));
      }
    },
    handleScroll(event) {
      const { deltaY } = event;
      const chatContainer = event.currentTarget;
      const atTop = chatContainer.scrollTop === 0;
      const scrollPosition =
        chatContainer.scrollTop + chatContainer.clientHeight;
      const nearBottom = chatContainer.scrollHeight - scrollPosition < 1; // Tolerance of 1 pixel

      if ((deltaY < 0 && atTop) || (deltaY > 0 && nearBottom)) {
        event.preventDefault();
      }
    },
    updateMessageState() {
      this.$store.dispatch("chat/updateMessageState", {
        chatId: this.conversation.id,
        newText: this.newMessage,
        newAudio: this.audioFile,
        newFile: this.selectedFile,
      });
    },
    scrollToBottom() {
      if (this.$refs.chatBody) {
        this.$refs.chatBody.scrollTop = this.$refs.chatBody.scrollHeight;
      }
    },
    removeSelectedFile() {
      this.selectedFile = null;
      this.uploadedFileData = null;
    },

    removeVideoFile() {
      this.audioFile = null;
      this.uploadedAudioData = null;
    },
    fileUpload(isFile) {
      let input = document.createElement("input");
      input.type = "file";

      if (isFile) {
        input.accept = ".pdf,.doc,.docx";
      } else {
        input.accept = "image/*,video/mp4";
      }

      input.onchange = async (e) => {
        let file = e.target.files[0];
        if (file) {
          this.selectedFile = file;
          this.updateMessageState();

          // Start upload immediately
          await this.uploadFile(file);
        }
        input.remove();
      };

      input.onblur = () => {
        input.remove();
      };

      input.click();
    },

    playAudio() {
      this.$refs.player.play();
    },
    pauseAudio() {
      this.$refs.player.pause();
    },

    async uploadFile(file) {
      this.uploadingSelectedFile = true;
      this.uploadProgress = 0;
      let formdata = new FormData();
      formdata.append("file", file, file.name);
      try {
        const res = await this.$axios.$post(
          this.$connect.FILE_UPLOAD_URL,
          formdata,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.uploadProgress = percentCompleted;
            },
          }
        );
        this.uploadedFileData = {
          url: res.data,
          meta: {
            filename: file.name,
            filesize: file.size,
            filetype: file.type,
          },
          type: this.getType(file.type),
        };
        this.selectedFile.uploadedUrl = this.uploadedFileData.url;
      } catch (err) {
        console.log(err);
        this.sendingError = err;
      } finally {
        this.uploadingSelectedFile = false;
      }
    },
    async onAudio(event) {
      this.audioFile = event;
      this.isRecording = false;
      this.updateMessageState();

      await this.uploadAudioFile(event);
    },
    startPhoneCall(conversation) {
      const routeData = this.$router.resolve({
        name: "users-id-communications-index-phone-call",
        params: {
          id: this.firstParticipant(conversation).id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    startVideoCall(conversation) {
      const routeData = this.$router.resolve({
        name: "users-id-communications-index-video_call",
        params: {
          id: this.firstParticipant(conversation).id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    toggleMinimize() {
      // Reset all recording states
      this.cleanupRecording();
      this.cancelRecording();

      // Original minimize logic
      this.$emit("toggleMinimize", { chatId: this.conversation.id }, true);
      if (this.channel) {
        this.channel.unsubscribe();
        this.channel.unbind("message-deleted-event", this.newDeletedMessage);
      }
    },
    restoreState(state) {
      this.newMessage = state.text || "";
      this.audioFile = state.audio || null;
      this.selectedFile = state.file || null;
    },
    closeChat() {
      // Reset all recording states
      this.cleanupRecording();
      this.cancelRecording();

      // Original close logic
      this.$emit("close", this.conversation.id);
      if (this.channel) {
        this.channel.unsubscribe();
        this.channel.unbind("message-deleted-event", this.newDeletedMessage);
      }
    },
    async uploadAudioFile(audioData) {
      if (!audioData || !audioData.blob) {
        return;
      }
      this.uploadingAudioFile = true;
      this.uploadProgress = 0;
      let formdata = new FormData();
      formdata.append(
        "file",
        audioData.blob,
        `chat_audio_${this.conversation.id}_${
          audioData?.duration || "0:00"
        }.mp3`
      );
      try {
        const res = await this.$axios.$post(
          this.$connect.FILE_UPLOAD_URL,
          formdata,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.uploadProgress = percentCompleted;
            },
          }
        );
        this.uploadedAudioData = {
          url: res.data,
          meta: {
            duration: this.parseDuration(audioData?.duration || "0:00"),
            filename: `chat_audio_${this.conversation.id}_${audioData?.duration}.mp3`,
            filesize: +parseFloat(audioData.blob.size / 100000).toFixed(2),
            filetype: audioData.blob.type.split("/").slice(-1).join(""),
          },
          type: "audio",
        };
        // Assign the uploaded URL to the audio file object
        this.audioFile.uploadedUrl = this.uploadedAudioData.url;
      } catch (err) {
        console.log(err);
        this.sendingError = err;
      } finally {
        this.uploadingAudioFile = false;
      }
    },
    async sendMessage() {
      if (this.uploadedAudioData) {
        const payload = {
          message: this.uploadedAudioData,
        };
        try {
          await this.$axios.$post(
            `${this.organization_id}/chat/${this.conversation.id}`,
            payload
          );
          this.uploadedAudioData = null;
          this.previewAudio = false;
          this.isRecording = false;
          this.cleanupRecording();
          await this.$nextTick();
          this.scrollToBottom();
        } catch (err) {
          this.showError(err);
        }
        return;
      }

      if (this.sendingMessage) {
        console.log(`already sending message`);
        return;
      }

      this.sendingError = null;

      if (
        this.newMessage.trim() === "" &&
        !this.audioFile &&
        !this.selectedFile
      ) {
        console.log(`nothing to send...`);
        return;
      }

      if (this.uploadingSelectedFile || this.uploadingAudioFile) {
        console.log("Please wait for the file to finish uploading");
        return;
      }

      this.sendingMessage = true;
      let channel =
        this.$vianovaChat.default_channel === "sms" ? "phone" : "text";
      if (this.conversation.direct_message) {
        channel = "text";
      }
      let payload = {
        message: {
          id: uuidv4(),
          timestamp: Date.now(),
          text: this.newMessage,
          type: channel,
        },
      };

      if (this.audioFile && this.audioFile.uploadedUrl) {
        // Safely check for properties before accessing them
        const blobType =
          this.audioFile.blob && this.audioFile.blob.type
            ? this.audioFile.blob.type
            : "audio/mpeg";
        const blobSize =
          this.audioFile.blob && this.audioFile.blob.size
            ? this.audioFile.blob.size
            : 0;

        payload = {
          message: {
            meta: {
              duration: this.parseDuration(this.audioFile?.duration || "0:00"),
              filename: `chat_audio_${this.audioFile.uploadedUrl}mp3`,
              filesize: +parseFloat(blobSize / 100000).toFixed(2),
              filetype: blobType.split("/").slice(-1).join(""),
            },
            timestamp: Date.now(),
            type: this.getType(blobType),
            url: this.audioFile.uploadedUrl,
          },
        };
      }

      console.log(
        "this.selectedFile , this.selectedFile.uploadedUrl",
        this.selectedFile
      );
      if (this.selectedFile && this.selectedFile.uploadedUrl) {
        console.log("SELECTED FILE UPLOADED URL", this.selectedFile);
        payload = {
          message: {
            meta: {
              filename: this.selectedFile.name || "file",
              filesize: this.selectedFile.size || 0,
              filetype: this.selectedFile.type || "application/octet-stream",
            },
            timestamp: Date.now(),
            type: this.getType(
              this.selectedFile.type || "application/octet-stream"
            ),
            url: this.selectedFile.uploadedUrl,
          },
        };
      }

      // Send the message
      try {
        let result = await this.$axios.$post(
          `${this.organization_id}/chat/${this.conversation.id}`,
          payload
        );

        this.sendingMessage = false;

        if (!result) return;
        console.log("result", result);

        // Clear states after successful send
        this.newMessage = "";
        this.audioFile = null;
        this.selectedFile = null;
        this.uploadProgress = 0;

        await this.$store.dispatch("chat/fetchMessages", {
          organization_id: this.organization_id,
          conversation_id: this.conversation.id,
          deleted: true,
        });

        // Wait for Vue to update the DOM
        await this.$nextTick();
        // Ensure messages are rendered before scrolling
        setTimeout(() => {
          this.scrollToBottom();
          if (this.$refs.newMessage) {
            this.$refs.newMessage.focus();
          }
        }, 100);

        this.$store.dispatch("chat/getInbox", {
          organizationId: localStorage.getItem("organizationId"),
          currentPage: 1,
        });
      } catch (e) {
        this.sendingMessage = false;
        this.sendingError = e;
        // Check if the showError method exists before calling it
        if (typeof this.showError === "function") {
          this.showError(e);
        } else {
          console.error("Error sending message:", e);
        }

        this.$nextTick(() => {
          if (this.$refs.newMessage) {
            this.$refs.newMessage.focus();
          }
        });
      }
    },

    parseDuration(durationString) {
      if (!durationString) {
        return 0;
      }

      const durationParts = durationString.split(":").reverse();

      let duration = 0;
      const multipliers = [1, 60, 60 * 60, 60 * 60 * 24];
      for (const [index, item] of durationParts.entries()) {
        if (index >= multipliers.length) {
          break;
        }

        duration += +item * multipliers[index];
      }

      return duration * 1000;
    },
    getType(type) {
      if (!type) return "file";

      return type.includes("image")
        ? "image"
        : type.includes("video")
        ? "video"
        : type.includes("audio")
        ? "audio"
        : "file";
    },
    async startRecording() {
      if (!this.recorder) {
        this.recorder = new AudioRecorder();
      }

      this.beforeRecording();
      await this.recorder.start();
      this.startDurationTimer();
    },

    beforeRecording() {
      this.isRecording = true;
      this.previewAudio = false;
    },

    startDurationTimer() {
      this.recordingDuration = 0;
      this.recordingInterval = setInterval(() => {
        this.recordingDuration++;
      }, 1000);
    },

    stopRecording() {
      if (!this.recordingInterval) return;

      if (this.recorder) {
        this.recorder.stop();
        const finalDuration = this.recordingDuration; // Store the final duration
        this.savingAudio = true;
        setTimeout(() => {
          const lastRecord = this.recorder.lastRecord()[0];
          if (lastRecord && lastRecord.blob) {
            this.audioElement = new Audio(
              URL.createObjectURL(
                new Blob([lastRecord.blob], { type: "audio/mp3" })
              )
            );

            let formdata = new FormData();
            formdata.append(
              "file",
              new Blob([lastRecord.blob], { type: "audio/mp3" }),
              `chat_audio_${this.conversation.id}_${this.formattedDuration}.mp3`
            );

            this.$axios
              .$post(this.$connect.FILE_UPLOAD_URL, formdata)
              .then((res) => {
                this.uploadedAudioData = {
                  meta: {
                    duration: finalDuration * 1000, // Use stored duration
                    filename: `chat_audio_${res.data}`,
                    filesize: +parseFloat(
                      lastRecord.blob.size / 100000
                    ).toFixed(2),
                    filetype: lastRecord.blob.type
                      .split("/")
                      .slice(-1)
                      .join(""),
                  },
                  text: this.text,
                  timestamp: Date.now(),
                  type: this.getType(lastRecord.blob.type),
                  url: res.data,
                };
                this.previewAudio = true;
                this.recordingDuration = finalDuration; // Restore the duration
                this.savingAudio = false;
              })
              .catch((err) => this.showError(err));
          }
        }, 100);

        // Don't clear the duration timer here
        if (this.recordingInterval) {
          clearInterval(this.recordingInterval);
          this.recordingInterval = null;
        }
      }
    },

    cancelRecording() {
      if (this.recorder) {
        this.recorder.stop();
      }

      this.isRecording = false;
      this.previewAudio = false;
      this.uploadedAudioData = null;

      if (this.audioElement) {
        this.audioElement.pause();
        URL.revokeObjectURL(this.audioElement.src);
        this.audioElement = null;
      }

      if (this.recordingInterval) {
        clearInterval(this.recordingInterval);
        this.recordingInterval = null;
      }

      this.isPlaying = false;
      this.audioProgress = 0;
      this.recordingDuration = 0;
    },

    clearDurationTimer() {
      if (this.recordingInterval) {
        clearInterval(this.recordingInterval);
        this.recordingInterval = null;
      }
      // Only reset duration if not in preview mode
      if (!this.previewAudio) {
        this.recordingDuration = 0;
      }
    },
    cleanupRecording() {
      if (this.audioElement) {
        this.audioElement.pause();
        URL.revokeObjectURL(this.audioElement.src);
        this.audioElement = null;
      }
      this.isPlaying = false;
      this.previewAudio = false;
      this.isRecording = false;
      this.audioProgress = 0;
      this.recordingDuration = 0;
      this.uploadedAudioData = null;

      // Clear any intervals
      if (this.recordingInterval) {
        clearInterval(this.recordingInterval);
        this.recordingInterval = null;
      }

      // Stop recorder if active
      if (this.recorder) {
        this.recorder.stop();
        this.recorder = null;
      }
    },
    togglePreviewPlayback() {
      if (this.audioElement) {
        if (this.isPlaying) {
          this.audioElement.pause();
        } else {
          this.audioElement.play();
        }
        this.isPlaying = !this.isPlaying;

        // Update progress while playing
        this.audioElement.ontimeupdate = () => {
          requestAnimationFrame(() => {
            this.audioProgress =
              (this.audioElement.currentTime / this.audioElement.duration) *
              100;
          });
        };

        this.audioElement.onended = () => {
          // Force a reset of the progress
          requestAnimationFrame(() => {
            this.audioProgress = 0;
            this.isPlaying = false;
            this.$forceUpdate(); // Force Vue to re-render the component
          });
        };
      }
    },
    async sendRecordedMessage() {
      if (this.uploadedAudioData) {
        try {
          await this.$axios.$post(
            `${this.organization_id}/chat/${this.conversation.id}`,
            {
              message: this.uploadedAudioData,
            }
          );

          // Reset states after successful send
          this.uploadedAudioData = null;
          this.previewAudio = false;
          this.isRecording = false;
          this.cleanupRecording();
          await this.$store.dispatch("chat/fetchMessages", {
            organization_id: this.organization_id,
            conversation_id: this.conversation.id,
            deleted: true,
          });

          this.$store.dispatch("chat/getInbox", {
            organizationId: localStorage.getItem("organizationId"),
            currentPage: 1,
          });

          this.$nextTick(() => {
            if (this.$refs.newMessage) {
              this.$refs.newMessage.focus();
            }
          });
        } catch (err) {
          this.showError(err);
        }
      }
    },
  },
};
