let userProfileNavigation = [
  {
    id: 12,
    label: "menuitems.menu.text",
    isTitle: true,
    display: true,
  },
  {
    id: 13,
    label: "menuitems.dashboard.text",
    icon: "uil-left-arrow-to-left",
    name: "dashboard",
    link: "/",
    display: true,
  },
  {
    id: 14,
    label: "menuitems.user_tabs.text",
    isTitle: true,
    display: true,
  },
  {
    id: 18,
    name: "reports",
    link: "reports",
    label: "menuitems.reports.text",
    icon: "uil-link",
    display: false,
  },
  {
    id: 15,
    name: "overview",
    label: "menuitems.overview.text",
    display: true,
    link: "overview",
    icon: "uil-apps",
  },
  {
    id: 19,
    name: "appointments",
    label: "menuitems.appointments.text",
    icon: "uil-map-pin-alt",
    display: false,
    link: "appointments",
  },
  {
    id: 20,
    name: "communications",
    label: "menuitems.communications.text",
    icon: "uil-calling",
    display: true,
    link: "communications",
  },
  {
    id: 25,
    name: "alerts",
    label: "menuitems.alerts.text",
    display: true,
    icon: "uil-bell",
    link: "alerts",
  },
  {
    id: 22,
    name: "calendar",
    label: "menuitems.calendar.text",
    icon: "uil-calendar-alt",
    display: false,
    link: "calendar",
  },
  {
    id: 21,
    name: "care-plan",
    label: "menuitems.care_plan.text",
    icon: "uil-book-medical",
    display: true,
    link: "tracks",
  },
  {
    id: 17,
    name: "attachments",
    link: "attachments",
    label: "menuitems.attachments.text",
    icon: "uil-link",
    display: false,
  },
  {
    id: 23,
    name: "devices",
    label: "menuitems.devices.text",
    icon: "fas fa-thermometer-quarter",
    display: true,
    link: "devices",
  },
  {
    id: 24,
    name: "reminders",
    label: "menuitems.reminders.text",
    icon: "uil-stopwatch",
    display: false,
    link: "reminders",
  },
  {
    id: 26,
    name: "plan",
    label: "Plan",
    display: false,
    icon: "uil-package",
    link: "plan",
  },
  {
    id: 27,
    name: "playground",
    label: "menuitems.more.text",
    display: true,
    icon: "uil-plus",
    link: "more",
  },
  {
    id: 28,
    name: "more",
    label: "Playground",
    display: process.env.NUXT_ENV_ENVIRONMENT !== "production",
    icon: "fas fa-atom",
    link: "playground",
  },
];
let homeNavigation = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
    display: true,
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    name: "dashboard",
    link: "/",
    display: true,
  },
  {
    id: 4,
    name: "view_patients",
    label: "menuitems.patients.text",
    link: "/patients",
    display: true,
    icon: "uil-users-alt",
  },
  {
    id: 9,
    name: "reports",
    label: "menuitems.reports.text",
    link: "/reports",
    display: true,
    icon: "uil-file-medical-alt",
  },
  {
    id: 10,
    name: "chats",
    badge: {
      id: "chat-badge-counter",
      variant: "warning",
      text: "0",
    },
    label: "menuitems.chats.text",
    link: "/chats",
    display: true,
    icon: "uil-comment-alt",
  },
  {
    id: 6,
    name: "alerts",
    label: "menuitems.alerts.text",
    link: "/alerts",
    display: true,
    icon: "uil-exclamation-triangle",
  },
  {
    id: 12,
    name: "appointments",
    label: "menuitems.appointments.text",
    link: "/appointments",
    display: true,
    icon: "uil-schedule",
  },
  {
    id: 13,
    name: "reminders",
    label: "menuitems.reminders.text",
    link: "/reminders",
    display: false,
    icon: "uil-notes",
  },
  {
    id: 3,
    name: "onboarding",
    label: "menuitems.onboarding.text",
    link: "/onboarding/create/patient_information",
    display: true,
    icon: "uil-book-medical",
  },
  {
    id: 7,
    name: "reminders",
    label: "Reminders",
    link: "/reminders",
    display: false,
    icon: "uil-apps",
  },
  {
    id: 8,
    name: "readings",
    label: "Readings",
    link: "/readings",
    display: false,
    icon: "uil-apps",
  },
];

let defaultAdminMenu = [
  {
    id: 1,
    label: "menuitems.users.text",
    icon: "uil-users-alt",
    link: "/admin/users",
    display: true,
  },
  {
    id: 2,
    label: "menuitems.specialists.text",
    link: "/admin/specialists",
    icon: "uil-suitcase-alt",
    display: true,
  },
  {
    id: 3,
    label: "menuitems.tracks.text",
    link: "/admin/tracks",
    icon: "uil-calendar-alt",
    display: true,
  },
  {
    isDivider: true,
    display: true,
  },
  {
    id: 4,
    label: "menuitems.roles.text",
    link: "/admin/roles",
    display: false,
  },
  {
    id: 5,
    label: "menuitems.onboard_medications.text",
    link: "/admin/onboard_medications",
    icon: "uil-medical-square-full",
    display: true,
  },
  {
    id: 6,
    label: "menuitems.organisations_settings.text",
    link: "/admin/organizations",
    icon: "uil-cog",
    display: true,
  },
  {
    id: 61,
    label: "menuitems.apps.text",
    link: "/admin/apps",
    icon: "uil-processor",
    display: true,
  },
  {
    id: 7,
    label: "menuitems.measurement_durations.text",
    link: "/admin/measurement_durations",
    icon: "uil-clock-two",
    display: true,
  },
];

let defaultMenu = [
  {
    id: 1,
    label: "menuitems.users.text",
    link: "/admin/users",
    display: true,
  },
  {
    id: 2,
    label: "menuitems.specialists.text",
    link: "/admin/specialists",
    display: true,
  },
  {
    id: 3,
    label: "menuitems.tracks.text",
    link: "/admin/tracks",
    display: true,
  },
];

export function generateMagicLinkMenu(route, app) {
  return [
    {
      id: 1,
      label: "menuitems.menu.text",
      isTitle: true,
      display: true,
    },
    {
      id: 2,
      label: "Home",
      icon: "uil-home-alt",
      name: "dashboard",
      link: `/magic-link/${localStorage.getItem("magic_link_id")}`,
      display: true,
    },
  ];
}
export function generateMenu(route, roles, app) {
  if (app.$vianovaConfig.options.navigation) {
    userProfileNavigation =
      app.$vianovaConfig.options.navigation.userProfileNavigation;
    defaultAdminMenu = app.$vianovaConfig.options.navigation.defaultAdminMenu;
    defaultMenu = app.$vianovaConfig.options.navigation.defaultMenu;
    homeNavigation = app.$vianovaConfig.options.navigation.homeNavigation;
  }
  if (roles.includes("Reports")) {
    homeNavigation = [
      {
        id: 1,
        label: "menuitems.menu.text",
        display: true,
        isTitle: true,
      },
      {
        id: 2,
        icon: "uil-home-alt",
        link: "/cannahealrx",
        name: "overview",
        label: "menuitems.dashboard.text",
        display: true,
      },
      {
        id: 18,
        icon: "fas fa-align-left",
        link: "/reports",
        name: "reports",
        label: "menuitems.reports.text",
        display: true,
      },
    ];
  }
  if (route.params.id && route.fullPath.includes("users")) {
    if (
      app.$vianovaConfig.slug === "oasis" ||
      app.$vianovaConfig.slug === "am-diabetes" ||
      app.$vianovaConfig.slug === "sayf"
    ) {
      userProfileNavigation[1] = {
        id: 13,
        icon: "uil-left-arrow-to-left",
        link: `/${localStorage.getItem("oasisLastStep")?.replace("-", "/")}${
          localStorage.getItem("patientPage") > 1
            ? "?page=" + localStorage.getItem("patientPage")
            : ""
        }`,
        name: "dashboard",
        label: localStorage.getItem("oasisLastStepLabel"),
        display: true,
      };
    }
    return userProfileNavigation;
  } else if (route.fullPath.includes("admin")) {
    if (roles.includes("SystemAdmin")) {
      return defaultAdminMenu;
    }
    return defaultMenu;
  } else {
    return homeNavigation;
  }
}
export default generateMenu;
