import { render, staticRenderFns } from "./default.vue?vue&type=template&id=89b312b4&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=89b312b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b312b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatManager: require('/opt/build/repo/components/chat/ChatManager.vue').default,Topbar: require('/opt/build/repo/components/Topbar.vue').default,Sidebar: require('/opt/build/repo/components/Sidebar.vue').default,OasisPatientDetails: require('/opt/build/repo/components/oasis/oasisPatientDetails.vue').default,Footer: require('/opt/build/repo/components/Footer.vue').default,NoteDrawerModal: require('/opt/build/repo/components/notes/modals/noteDrawerModal.vue').default})
