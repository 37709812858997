import moment from "moment";

export const state = () => ({
  firstStep: {
    provider: null,
    state: null,
    service: null,
    date: moment().format("L"),
    time: {
      weekdays: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
      from: "7 AM",
      to: "5 PM",
    },
  },
  secondStep: {
    timestamps: [],
    selectedTimestamp: null,
    pagination: {
      allPages: 0,
      currentPage: 0,
    },
  },
  thirdStep: {
    validations: {
      hasErrors: true,
      emailError: true,
      phoneError: true,
      dobErrors: true,
    },
    inputs: [
      {
        key: "first_name",
        value: null,
        required: true,
      },
      {
        key: "last_name",
        value: null,
        required: true,
      },
      {
        key: "middle_initial",
        value: null,
        required: false,
      },
      {
        key: "email",
        value: null,
        required: true,
      },
      {
        key: "confirm_email",
        value: null,
        required: true,
      },
      {
        key: "phone",
        value: null,
        required: true,
      },
      {
        key: "confirm_phone",
        value: null,
        required: true,
      },
      {
        key: "date_of_birth",
        value: null,
        required: true,
      },
      {
        key: "name_suffix",
        value: null,
        required: true,
      },
      {
        key: "drivers_license",
        value: null,
        required: true,
      },
      {
        key: "street_address",
        value: null,
        required: true,
      },
      {
        key: "city",
        value: null,
        required: true,
      },
      {
        key: "state",
        value: null,
        required: true,
      },
      {
        key: "zip_code",
        value: null,
        required: true,
      },
      {
        key: "medical_condition",
        value: [],
        required: true,
      },
      {
        key: "gender",
        value: null,
        required: true,
      },
      {
        key: "prior_medical_cannabis_certification",
        value: null,
        required: true,
      },
      {
        key: "new_or_renewing_patient",
        value: null,
        required: true,
      },
      {
        key: "filling_for_minor",
        value: null,
        required: true,
      },
      {
        key: "minor_first_name",
        value: null,
        required: false,
      },
      {
        key: "minor_last_name",
        value: null,
        required: false,
      },
      {
        key: "minor_date_of_birth",
        value: null,
        required: false,
      },
      {
        key: "current_medical_cannabis_use",
        value: null,
        required: true,
      },
      {
        key: "current_prescription_medications",
        value: null,
        required: true,
      },
      {
        key: "list_of_medications",
        value: null,
        required: false,
      },
      {
        key: "allergies_to_medications",
        value: null,
        required: true,
      },
      {
        key: "list_of_allergies",
        value: null,
        required: false,
      },
      {
        key: "tobacco_use",
        value: null,
        required: true,
      },
      {
        key: "how_often_tobacco",
        value: null,
        required: false,
      },
      {
        key: "alcohol_use",
        value: null,
        required: true,
      },
      {
        key: "how_often_alcohol",
        value: null,
        required: false,
      },
      {
        key: "additional_medical_condition_info",
        value: null,
        required: true,
      },
      {
        key: "preferred_dispensary",
        value: null,
        required: false,
      },
      {
        key: "dispensary_name_location",
        value: null,
        required: false,
      },
      {
        key: "dispensary_employee",
        value: null,
        required: false,
      },
      {
        key: "dispensary_name_location_phone",
        value: null,
        required: false,
      },
      {
        key: "upload_id",
        value: null,
        required: false,
      },
      {
        key: "medical_record_upload",
        value: null,
        required: false,
      },
      {
        key: "referral_code",
        value: null,
        required: false,
      },
      {
        key: "referral_source",
        value: null,
        required: false,
      },
    ],
  },
  fourthStep: {
    stripeRef: null,
  },

  couponData: null,
});

function splitArrayIntoChunks(array, chunkSize) {
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    let chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

function splitNestedArrays(originalArray, firstChunkSize, secondChunkSize) {
  let firstLevelChunks = splitArrayIntoChunks(originalArray, 70);

  let result = firstLevelChunks.map((chunk) => splitArrayIntoChunks(chunk, 9));

  return result;
}

const regexEmail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const checkForPhoneErrors = (phone, confirm) => {
  const formatIsCorrect =
    phone && phone.length === 12 && confirm && confirm.length === 12;
  const phoneIsConfirmed = phone === confirm;
  if (formatIsCorrect && phoneIsConfirmed) return false;
  return true;
};

const checkForDobErrors = (date_of_birth) => {
  const currentDate = moment();
  if (!date_of_birth) return true;
  if (moment(date_of_birth).isAfter(currentDate)) return true;
  if (currentDate.diff(moment(date_of_birth), "years") < 18) {
    console.log(currentDate.diff(moment(date_of_birth), "years") < 18);

    return true;
  }
  console.log(currentDate.diff(moment(date_of_birth), "years") < 18);
  return false;
};

const checkForEmailErrors = (email, confirm) => {
  if (!email || !email.length || !confirm || !confirm.length) return false;
  const formatIsCorrect = regexEmail.test(email) && regexEmail.test(confirm);
  const emailIsConfirmed = email === confirm;
  if (formatIsCorrect && emailIsConfirmed) return false;
  return true;
};

const checkForInputErrors = (inputs) => {
  if (!inputs.length) return true;

  const requiredFields = inputs.filter((e) => {
    return e.required;
  });
  console.log(requiredFields, "aaaaaa");
  const thereAreIncompleteFields = requiredFields.some((e) => {
    return e.value === null || !e.value.length;
  });
  return thereAreIncompleteFields;
};

export const mutations = {
  SET_FIRST_STEP_DATA(state, data) {
    state.firstStep[data.point] = data.data;
  },
  SET_TIMESTAMP_PAGE_CHENGE(state, data) {
    state.secondStep.pagination.currentPage =
      data === "increase"
        ? state.secondStep.pagination.currentPage + 1
        : state.secondStep.pagination.currentPage - 1;
  },
  SET_TIMESTAMP(state, data) {
    state.secondStep.selectedTimestamp = data;
  },
  TIMESTAMP_SETTER(state, data) {
    let allTimeSlots = [];
    let selectedTimeslots = data[Object.keys(data)[0]];

    state.firstStep.provider = { id: Object.keys(data)[0] };

    for (const key in selectedTimeslots) {
      const element = selectedTimeslots[key];
      allTimeSlots.push({
        end: null,
        start: null,
        date: key,
        fullTimeFormat: key,
        disabled: true,
        is_date: true,
      });
      let mutatedElement = element.map((e) => {
        return {
          ...e,
          date: key,
          fullTimeFormat: `${e.start} ${e.end} ${key}`,
          disabled: true,
          is_date: false,
        };
      });
      allTimeSlots.push(mutatedElement);
    }

    let flattenedTimeSlots = allTimeSlots.flat(1);

    // Updated chunk size to 40 items
    let paginatedTimeSlots = splitArrayIntoChunks(flattenedTimeSlots, 54);

    // Adjust the nested arrays according to columns per chunk (totalColumnsToGenerate)
    let mutatedPaginatedTimeslots = paginatedTimeSlots.map((chunk) =>
      splitNestedArrays(chunk, 10)
    );

    state.secondStep.timestamps = mutatedPaginatedTimeslots;
    state.secondStep.pagination.allPages = mutatedPaginatedTimeslots.length;
    for (const day of mutatedPaginatedTimeslots) {
      for (const groups of day) {
        for (const group of groups) {
          for (const timeslot of group) {
            if (!timeslot.is_date && timeslot.start && timeslot.end) {
              state.secondStep.selectedTimestamp = { ...timeslot };
              return;
            }
          }
        }
      }
    }
  },
  SET_THIRD_STEP_DATA(state, data) {
    state.thirdStep["inputs"] = [...data];

    state.thirdStep["validations"]["hasErrors"] = checkForInputErrors(
      state.thirdStep["inputs"]
    );

    const email = state.thirdStep["inputs"].find((e) => {
      return e.key === "email";
    }).value;

    const confirmEmail = state.thirdStep["inputs"].find((e) => {
      return e.key === "confirm_email";
    }).value;
    const phone = state.thirdStep["inputs"].find((e) => {
      return e.key === "phone";
    }).value;

    const confirmPhone = state.thirdStep["inputs"].find((e) => {
      return e.key === "confirm_phone";
    }).value;

    const date_of_birth = state.thirdStep["inputs"].find((e) => {
      return e.key === "date_of_birth";
    }).value;

    state.thirdStep["validations"]["emailError"] = checkForEmailErrors(
      email,
      confirmEmail
    );

    state.thirdStep["validations"]["phoneError"] = checkForPhoneErrors(
      phone,
      confirmPhone
    );
    state.thirdStep["validations"]["dobErrors"] =
      checkForDobErrors(date_of_birth);
  },
  SET_COUPON_DATA(state, data) {
    state.couponData = data;
  },
  SET_THIRD_STEP_INPUTS(state, data) {
    state.thirdStep["inputs"]["inputs"] = [];
    state.thirdStep["inputs"]["inputs"] = data.data;
    const firstName = state.thirdStep["inputs"]["first_name"];
    const lastName = state.thirdStep["inputs"]["last_name"];

    state.thirdStep["validations"]["hasErrors"] = checkForInputErrors(
      state.thirdStep["inputs"]["inputs"],
      firstName,
      lastName
    );
  },
  SET_STRIPE_REF(state, data) {
    if (state.fourthStep.stripeRef) return;
    state.fourthStep.stripeRef = data;
  },
  RESET_STATE(state) {
    // Reset all state properties to their initial values
    state.firstStep = {
      state: null,
      service: null,
      date: null,
    };
    state.secondStep = {
      timestamps: [],
      selectedTimestamp: null,
      pagination: {
        currentPage: 0,
        allPages: 0,
      },
    };
    state.thirdStep = {
      inputs: [],
      validations: {
        hasErrors: false,
        phoneErrors: false,
        emailErrors: false,
        dobErrors: false,
      },
    };
    state.fourthStep = {
      couponData: null,
    };
  },
};

export const actions = {
  setInputs({ commit }, inputs) {
    commit("SET_INPUTS", inputs);
  },
  updateStripeElementCard({ commit }, card) {
    commit("SET_STRIPE_REF", card);
  },
};

export const getters = {
  getFirstSteps: (state) => () => {
    return state.firstStep;
  },
  getSecondSteps: (state) => () => {
    return state.secondStep;
  },
  getThirdSteps: (state) => () => {
    return state.thirdStep;
  },
  getFourthSteps: (state) => () => {
    return state.fourthStep;
  },
  getCouponData: (state) => () => {
    return state.couponData;
  },
};
