import { render, staticRenderFns } from "./user.vue?vue&type=template&id=191ae50e&scoped=true&"
import script from "./user.vue?vue&type=script&lang=js&"
export * from "./user.vue?vue&type=script&lang=js&"
import style0 from "./user.vue?vue&type=style&index=0&id=191ae50e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191ae50e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Topbar: require('/opt/build/repo/components/Topbar.vue').default,Sidebar: require('/opt/build/repo/components/Sidebar.vue').default,Footer: require('/opt/build/repo/components/Footer.vue').default,RightSidebar: require('/opt/build/repo/components/Right-sidebar.vue').default})
