
import Swal from "sweetalert2";
import debounce from "lodash/debounce";
import {
  advancedFiltersGetters,
  usersGetters,
  trackerGetters,
  amdiabetesSearchGetters,
  layoutComputed,
  organizationsGetters,
  organizationMutations,
  configGetters,
} from "~/store/helpers";
import patientFilterModal from "./patientFilters/patientFilterModal.vue";
import { mapState } from "vuex";
import moment from "moment";
import Pusher from "pusher-js";

/**
 * Topbar component
 */
export default {
  components: { patientFilterModal },
  props: {
    page: String,
  },
  data() {
    return {
      lastMessageId: null,
      newMessage: null,
      channel: null,
      pusher: null,
      channelNewMessages: null,
      userInactive: false,
      timer: null,
      showModal: false,
      inactivityTimeout: null,
      languages: [
        {
          flag: require("~/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("~/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Spanish",
        },
        {
          flag: require("~/assets/images/flags/turkey.jpg"),
          language: "tr",
          title: "Türkçe",
        },
        {
          flag: require("~/assets/images/flags/germany.jpg"),
          language: "de",
          title: "German",
        },
      ],
      filterSearch: null,
      patientFilterSelected: null,
      current_language: this.$i18n.locale,
      language: null,
      window_width: window.innerWidth,
      defaultTimerStopTime: 300,
      modalIsActive: false,
      search: null,
    };
  },
  // beforeDestroy() {
  //   clearInterval(this.timer);
  //   this.channelNewMessages.unbind();
  // },
  beforeCreate() {
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale");
    } else {
      this.$i18n.locale = this.$vianovaConfig.options.locale;
      localStorage.setItem("locale", this.$i18n.locale);
    }
  },
  async mounted() {
    if (
      !this.$auth.user.signature_path &&
      (this.$auth.user.all_roles.includes("Physician") || this.$auth.user.all_roles.includes("ClientAdmin")) &&
      this.$veruFlag("provider_signature")
    ) {
      this.$bvModal.show("completeProfile");
    }
    // this.$store.dispatch("chat/getUnreadMessageCount");
    // window.addEventListener("resize", this.getWindowWidth);
    // this.setLogoutTimer();
    // let channelName = `trackers.${localStorage.getItem("organizationId")}`;
    // this.pusher = new Pusher(this.$connect.pusherAppKey, {
    //   cluster: "mt1",
    // });
    // this.channel = this.pusher.subscribe(channelName);

    // console.log(`subscribed to channel ${channelName}`, this.channel);

    // // if (!this.$veruFlag("chat")) {
    // this.channelNewMessages = this.pusher.subscribe(
    //   `user_${this.$auth.user.id}`
    // );
    // console.log(
    //   `subscribed to channel "user_${this.$auth.user.id}""`,
    //   this.channelNewMessages
    // );
    // this.channelNewMessages.bind("new-message-event", async (event) => {
    //   if (event.message.sender_id == this.$auth.user.id) return;
    //   console.log(`received new message event`, event);
    //   // if (this.lastMessageId === event.message.id) return;
    //   // this.lastMessageId = event.message.id;
    //   let messageData = await this.$axios.$get(
    //     `${localStorage.getItem("organizationId")}/chat/message/${
    //       event.message.id
    //     }`
    //   );
    //   let body = JSON.parse(messageData.data.body);

    //   this.makeToast(
    //     {
    //       ...messageData.data,
    //       body: body,
    //     },
    //     true
    //   );

    //   this.$store.dispatch("chat/receivedNewMessageNotification", event);
    // });
    // }

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    this.language = this.languages.find(
      (x) => x.language === this.$i18n.locale
    );

    if (!this.$auth.user) return;
    this.value = this.$auth.user.all_organizations.find(
      (x) => x.id === localStorage.getItem("organizationId")
    );
    if (!this.value) this.value = this.$auth.user.all_organizations[0];
    this.SET_ORGANIZATION(this.value);

    this.showTrackerWarning();
  },
  computed: {
    ...trackerGetters,
    ...advancedFiltersGetters,
    ...layoutComputed,
    ...organizationsGetters,
    ...configGetters,
    ...amdiabetesSearchGetters,
    ...usersGetters,
    ...mapState("chat", {
      conversations: (state) => state.conversations,
    }),
    ...mapState("chat", {
      inbox_open: (state) => state.inbox_open,
    }),
    ...mapState("trackers", ["trackers"]),
    currentTrackerId() {
      return this.getCurrentTrackerId();
    },

    isInboxOpen() {
      return this.window_width <= 600 && this.inbox_open;
    },

    shouldTopBarShow() {
      if (!this.conversations.length) return true;
      if (this.window_width <= 600) {
        if (
          this.conversations.some((e) => {
            return !e.isMinimized;
          })
        ) {
          return false;
        }
        if (this.inbox_open === true) {
          return false;
        }
      }

      return true;
    },
    startedTimer() {
      return this.getStartedTimer();
    },
    AMDPatientSearch() {
      return this.getPatientSearch();
    },
    currentTracker() {
      return this.getCurrentTracker();
    },
    logoRedirect() {
      return {
        name: this.$vianovaConfig.options.home_path ?? "patients",
      };
    },
    isProduction() {
      return process.env.NUXT_ENV_ENVIRONMENT !== "production";
    },
    organizations() {
      return this.$auth.user.all_organizations;
    },
    displayLanguages() {
      return this.languages.filter((e) => {
        return this.$vianovaConfig.options.locales.includes(e.language);
      });
    },
    shouldInactiveTimerShow() {
      return this.inactivityReached();
    },
    role() {
      return this.$auth.user ? this.$auth.user.all_roles : [];
    },
  },
  watch: {
    currentTrackerId(val) {
      console.log(val);
      this.channel.unbind("tracker-time-out");
      if (val) {
        document.addEventListener("mousemove", this.resetInactivityTimeout);
        document.addEventListener("click", this.resetInactivityTimeout);
        document.addEventListener("scroll", this.resetInactivityTimeout);
        this.channel.bind(`tracker-time-out`, (event) => {
          console.log(`changed event...`, event);
          this.$store.commit("trackers/SET_SHOW_INACTIVITY", true);
        });
      }
      if (!val) {
        document.removeEventListener("mousemove", this.resetInactivityTimeout);
        document.removeEventListener("click", this.resetInactivityTimeout);
        document.removeEventListener("scroll", this.resetInactivityTimeout);
      }
    },
    shouldInactiveTimerShow(val) {
      if (val === true) {
        this.$bvModal.show("inactiveTimer");
        let timeToStop = setInterval(async () => {
          this.defaultTimerStopTime = this.defaultTimerStopTime - 1;
          if (this.defaultTimerStopTime <= 0) {
            this.defaultTimerStopTime = 300;
            await this.stopTimer();
            this.$store.commit("trackers/SET_SHOW_INACTIVITY", false);
            this.$store.dispatch("trackers/stopTimer");

            clearInterval(timeToStop);
          }
        }, 1000);
      }
      if (val === false) {
        this.$bvModal.hide("inactiveTimer");
        this.$store.commit("trackers/SET_SHOW_INACTIVITY", false);
      }
    },
    search(newVal, oldVal) {
      if (oldVal && oldVal.trim().length) {
        this.debounceSearch();
        return;
      }
      if (newVal && newVal.trim().length) {
        this.debounceSearch();
        return;
      }
    },
    "$route.fullPath": {
      immediate: true,
      deep: true,
      handler(oldVal, newVal) {
        if (
          this.$route.name !== "patients" &&
          !this.$route.name.includes("cannahealrx")
        ) {
          this.resetSearch();
        }
        if (
          oldVal.includes("all_patients") ||
          oldVal.includes("my_patients") ||
          oldVal.includes("call_list") ||
          oldVal.includes("members")
        ) {
          this.search = this.AMDPatientSearch;
        }
        if (oldVal === "/patients") {
          this.getPatientFilter();
        }
        if (
          oldVal != newVal &&
          this.$route.params?.name != "dashboard" &&
          newVal == "/dashboard/my_patients"
        ) {
          this.closeFilterModal();
          this.$store.commit(
            "modules/amdiabetes-search/SET_SEARCH",
            this.search
          );
          this.$store.commit("modules/admin/SET_SEARCH", {
            page: null,
            search: null,
          });
        }
        if (newVal === "/dashboard/onboarding") {
          this.$store.commit("modules/amdiabetes-search/SET_SEARCH", null);
        }
        this.showTrackerWarning(newVal, oldVal);
      },
    },
  },
  methods: {
    makeToast(message, append = false) {
      const h = this.$createElement;
      const displayText = message.body?.text || this.$t("chat.new_message");
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        `${displayText}`,
        " ",
        h("br"),
        h(
          "a",
          {
            attrs: {
              href: `/users/${message.sender.id}/communications/text`,
              target: "_blank",
            },
          },
          this.$t("chat.read_now")
        ),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
        [
          h("strong", { class: "mr-2" }, `${message.sender.display_name}`),
          h(
            "small",
            { class: "ml-auto text-italics" },
            `${moment(message.created_at).fromNow()}`
          ),
        ]
      );
      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: "info",
        noAutoHide: true,
        appendToast: append,
      });

      // this.$bvToast.toast(`${message.body.text}`, {
      //   title: `Message from ${message.sender.display_name}`,
      //   variant: "primary",

      //   appendToast: append,
      // });
    },
    getWindowWidth() {
      this.window_width = window.innerWidth;
    },
    async stopTimer() {
      await this.$store.dispatch("modules/users/stopTracker", {
        organizationId: localStorage.getItem("organizationId"),
        patientId: this.$route.params.id,
        payload: { type: this.currentTracker.type.toLowerCase() },
      });
      this.$bvModal.show("stopSuccess");
    },
    resetInactivityTimeout() {
      if (this.currentTrackerId) {
        if (
          !this.shouldInactiveTimerShow &&
          this.userInactive === true &&
          this.currentTrackerId
        ) {
          this.proceedTracker();
        }
        this.userInactive = false;
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
          if (!this.shouldInactiveTimerShow && this.currentTrackerId) {
            this.proceedTracker();
          }
          this.userInactive = true;
          console.log("Mouse stopped moving, clicking, and scrolling.");
        }, 10000);
      }
    },
    // setLogoutTimer() {
    //   this.timer = setInterval(() => {
    //     try {
    //       if (this.$auth.strategy.token.status().expired()) {
    //         clearInterval(this.timer);
    //         Swal.fire({
    //           title: "Session Expired",
    //           text: "Your session has expired and you have been automatically logged out!",
    //           icon: "warning",
    //           allowOutsideClick: false,
    //           allowEscapeKey: false,
    //           showCancelButton: false,
    //           confirmButtonColor: "#74788D",
    //           cancelButtonColor: "#F46A6A",
    //           confirmButtonText: "Log In Again",
    //         }).then((result) => {
    //           this.$router.push({
    //             name: "auth-login",
    //           });
    //         });
    //       }
    //     } catch (e) {}
    //   }, 1000);
    // },
    async showTrackerWarning(oldVal, newVal) {
      await this.$store.dispatch("trackers/fetchTrackers", {
        organization_id: localStorage.getItem("organizationId"),
      });

      if (
        oldVal &&
        oldVal.includes("users") &&
        newVal &&
        newVal.includes("users")
      ) {
        return;
      }
      if ((newVal && !newVal.includes("users")) || !newVal) {
        if (this.trackers.length) {
          let tracker = this.trackers[0];
          console.log(tracker);
          if (!this.startedTimer) {
            this.$store.dispatch("trackers/startTimer", {
              data: {
                type: tracker.type,
                timer: moment().diff(tracker.started_at, "seconds"),
                time: moment(tracker.started_at).format(),
              },
            });
            this.$store.commit("modules/users/SET_TRACKER_ID", tracker.id);
          }
          Swal.fire({
            title: "Active Tracker",
            text: "You currently have an active tracker for one of your patients. Please complete the tracker before continuing.",
            icon: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            confirmButtonColor: "#74788D",
            cancelButtonColor: "#F46A6A",
            confirmButtonText: "View Tracker",
          }).then((result) => {
            this.$router.push({
              name: "users-id-overview",
              params: {
                id: this.trackers[0].patient.id,
              },
            });
          });
        }
      }
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
      this.$store.commit("modules/layout/SET_CONDENSED", false);
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    refreshPage() {
      window.location.reload();
    },
    setLanguage(locale) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      localStorage.setItem("locale", locale);
      window.location.reload();
    },
    async logoutUser() {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        this.$t("auth.logout.title"),
        {
          title: this.$t("navbar.dropdown.marcus.list.logout"),
          okTitle: this.$t("auth.logout.confirm"),
          cancelTitle: this.$t("auth.logout.cancel"),
          okVariant: "primary",
          centered: true,
        }
      );

      if (!confirmed) {
        return;
      }

      await this.$auth.logout();
      this.showSuccess(this.$t("auth.goodbye"));
      localStorage.clear();
      await this.$router.push({
        path: "/auth/login",
      });
    },
    ...organizationMutations,
    setOrganization(id) {
      let organization = this.organizations.find((e) => e.id == id);
      this.SET_ORGANIZATION(organization);
      this.$route.fullPath.includes("admin")
        ? this.$router.push("/admin")
        : this.$router.push("/");
      setTimeout(() => {
        this.$store.commit("modules/cgm_sync/SET_SYNCING_STATUS", "");
      }, 1000);
    },
    formatSeconds(timer) {
      const duration = moment.duration(timer, "seconds");
      return moment.utc(duration.asMilliseconds()).format("mm:ss");
    },
    showFilterModal() {
      this.showModal = true;
    },
    closeFilterModal(val) {
      this.showModal = val;
      this.filterSearch = null;
      this.$forceUpdate();
    },
    selectPatientFilter(val) {
      this.patientFilterSelected = val;
      this.showModal = false;
    },
    async proceedTracker() {
      console.log("PROCEED ENTERED HERE!!!");
      await this.$axios.post(
        `${localStorage.getItem("organizationId")}/patients/${
          this.$route.params.id
        }/trackers/${this.currentTrackerId}/resume`
      );
    },
    async getPatientFilter() {
      await this.$store.dispatch("filters/fetchExistingFilters", {
        organizationId: localStorage.getItem("organizationId"),
      });
    },
    removePatientFilter() {
      this.$store.commit("modules/users/SET_LOADING", true);
      this.patientFilterSelected = null;
      localStorage.removeItem("filterActive");
      this.$store
        .dispatch("modules/users/getUsers", {
          organizationId: localStorage.getItem("organizationId"),
          currentPage: 1,
          perPage: 15,
        })
        .then(() => {
          this.$store.commit("modules/users/SET_LOADING", false);
        });
    },
    debounceSearch: debounce(function () {
      this.searchInput();
    }, 300),
    async searchInput() {
      this.$store.commit("modules/search/SET_SEARCH", this.search);
      if (
        this.$route.name.includes("patients") ||
        this.$route.name.includes("call_list") ||
        this.$route.name.includes("members")
      ) {
        this.$router
          .replace({
            name: this.$route.name,
            query: { ...this.$route.query, search: this.search },
          })
          .catch((error) => {
            // console.log("topbar router", error);
          });
      }
      let organizationId = localStorage.getItem("organizationId");
      let routeName = this.$route.name;
      if (this.$route.name.includes("notifications")) {
        await this.$store.dispatch("modules/notifications/getNotifications", {
          organizationId,
          perPage: 15,
          currentPage: 1,
          search: this.search,
        });
        await this.$store.dispatch(
          "modules/notifications/setSearchInput",
          this.search
        );
        return;
      }

      if (
        routeName.includes("my_patients") ||
        routeName.includes("all_patients") ||
        routeName === "dashboard-call_list" ||
        routeName === "civiclee-members" ||
        routeName === "dental-onboarding"
      ) {
        this.$store.commit(
          "modules/amdiabetes-search/SET_PATIENT_SEARCH",
          this.search
        );
        return;
      }
      if (routeName.includes("overview")) {
        if (routeName === "overview" && this.search) {
          if (
            this.$auth.user.all_roles.includes("Provider") ||
            this.$auth.user.all_roles.includes("Nurse")
          ) {
            this.$router.push({
              name: "dashboard-my_patients",
            });
          } else {
            this.$router.push({
              name: "dashboard-all_patients",
            });
          }
        }
        this.$store.commit(
          "modules/amdiabetes-search/SET_PATIENT_SEARCH",
          this.search
        );

        this.$store.commit("modules/amdiabetes-search/SET_SEARCH", this.search);
        return;
      }
      //For envirorments with old dashboard
      if (routeName.includes("dashboard")) {
        if (routeName === "dashboard" && this.search) {
          this.$router.push({
            name: "dashboard-my_patients",
          });
        }
        this.$store.commit(
          "modules/amdiabetes-search/SET_PATIENT_SEARCH",
          this.search
        );

        this.$store.commit("modules/amdiabetes-search/SET_SEARCH", this.search);
        return;
      }
      if (routeName.includes("civiclee")) {
        this.$store.commit("groups/setSearch", this.search);
        return;
      }
      if (routeName.includes("admin")) {
        let page = routeName.includes("organisations_settings")
          ? "organization"
          : routeName.split("-").slice(-1).join("");
        this.$store.commit("modules/admin/SET_SEARCH", {
          page: page,
          search: this.search || "",
        });
      } else {
        if (routeName == "index-name" || routeName == "patients") {
          this.$store.commit("modules/users/SET_LOADING", true);
          this.$store.commit("modules/users/SET_SEARCH", this.search);

          let searchResult = await this.$axios.$get(
            `${organizationId}/patients?search=${this.search}`
          );
          this.$store.commit("modules/users/SET_USERS", searchResult);
          if (routeName == "index-name") {
            this.$router.push({
              name: "patients",
              params: { name: "dashboard" },
            });
          }
          this.$store.commit("modules/users/SET_LOADING", false);
        } else {
          if (routeName == "index") return;
          this.$store.commit(
            `modules/${routeName}/SET_CURRENT_${routeName.toUpperCase()}`,
            {
              search: this.search,
            }
          );
        }
        // this.$route.name == "chats"
        // this.$axios.$get(`${organizationId}/patients?inbox=true`)
      }
    },
    resetSearch() {
      this.search = null;
      this.$store.commit("modules/search/SET_SEARCH", null);
    },
  },
};
